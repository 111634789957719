import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import CertificationShowcase from "./AboutUsSections/CertificationShowcase";
import { styled } from "@mui/system";
import { Typography, Container } from "@mui/material";
import OurExperties from "../screens/AboutUsSections/OurExperties";

import {
  ChevronDown,
  Menu,
  X,
  Server,
  Database,
  Cloud,
  Shield,
  Code,
  Laptop,
  Users,
  Target,
  Zap,
  Award,
  ThumbsUp,
  Briefcase,
  Mail,
  Phone as PhoneIcon,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  ArrowRight,
  ArrowLeft,
  Eye,
  Crosshair,
  Box,
  Cpu,
  Settings,
  Cog,
  GitBranch,
  ArrowUpCircle,
  Shuffle,
  RefreshCw,
  Network,
  Clock,
  FileCheck,
  Repeat,
  Workflow,
  Layers,
  Rocket,
  Clock as ClockIcon,
  PenTool,
  Monitor,
  Calculator,
  GraduationCap,
} from "lucide-react";
import Button from "../components/button";

const services = [
  {
    name: "Datacenter & Operations",
    icon: Server,
    description:
      "Enterprise-grade infrastructure solutions and operational excellence",
    subServices: [
      { name: "Virtualization", icon: Box },
      { name: "HCI", icon: Database },
      { name: "Management", icon: Briefcase },
      { name: "Operations", icon: Target },
      { name: "Infrastructure Automation", icon: Zap },
    ],
  },
  {
    name: "Workload Migrations",
    icon: Laptop,
    description: "Seamless transition of workloads across platforms",
    subServices: [
      { name: "Migration Strategy", icon: Target },
      { name: "P2V", icon: ArrowUpCircle },
      { name: "V2V", icon: Shuffle },
      { name: "X2V", icon: RefreshCw },
      { name: "X2C", icon: Cloud },
    ],
  },
  {
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    description: "Ensuring business resilience and data protection",
    subServices: [
      { name: "Local & Remote Availability", icon: Server },
      { name: "RPO, RTO, SLA", icon: Clock },
      { name: "Runbooks", icon: FileCheck },
      { name: "Failover & Failback", icon: Repeat },
      { name: "DR Automation", icon: Zap },
    ],
  },
  {
    name: "Database Modernization",
    icon: Database,
    description: "Modern database solutions for digital transformation",
    subServices: [
      { name: "Database as a Service", icon: Cloud },
      { name: "Database Migrations", icon: Shuffle },
      { name: "Database Fitchecks", icon: FileCheck },
      { name: "Database Automations", icon: Zap },
    ],
  },
  {
    name: "Cloud Automations",
    icon: Cloud,
    description: "Automated cloud solutions for enhanced efficiency",
    subServices: [
      { name: "Private Cloud", icon: Shield },
      { name: "Hybrid Cloud", icon: Cloud },
      { name: "3rd Party Integrations", icon: Workflow },
      { name: "Application Automations", icon: Zap },
      { name: "Process Automations", icon: Cog },
    ],
  },
  {
    name: "App Modernization",
    icon: Code,
    description: "Modernizing applications for the cloud era",
    subServices: [
      { name: "Containerization", icon: Box },
      { name: "Kubernetes Platform", icon: Cpu },
      { name: "CI/CD Pipelines", icon: GitBranch },
      { name: "Cloud-native Apps", icon: Cloud },
      { name: "Microservices", icon: Layers },
    ],
  },
];

const values = [
  {
    title: "Innovation",
    description: "Embracing cutting-edge technologies to drive progress",
    icon: Zap,
  },
  {
    title: "Excellence",
    description: "Striving for the highest quality in everything we do",
    icon: Award,
  },
  {
    title: "Integrity",
    description:
      "Maintaining honesty and ethical standards in all our dealings",
    icon: ThumbsUp,
  },
  {
    title: "Collaboration",
    description: "Working together to achieve common goals",
    icon: Users,
  },
];

const stages = [
  {
    title: "CONSULT",
    description:
      "Our team of seasoned professionals will collaborate closely with you to gain a thorough understanding of your business needs and goals.",
    icon: Briefcase,
    image: "/consult-bg.jpg",
  },
  {
    title: "DESIGN",
    description:
      "Leveraging their wealth of experience, our skilled architects will craft bespoke solutions tailored to your unique requirements.",
    icon: PenTool,
    image: "/design-bg.jpg",
  },
  {
    title: "BUILD",
    description:
      "Our talented developers and automation engineers will transform your IT vision into reality, constructing robust and scalable solutions.",
    icon: Cpu,
    image: "/build-bg.jpg",
  },
  {
    title: "TRAIN",
    description:
      "We offer comprehensive training programs that deliver hands-on experience and knowledge transfer, empowering your team to excel.",
    icon: GraduationCap,
    image: "/train-bg.jpg",
  },
];

// style components which i installed

// const StyledSection = styled('section')(({ theme }) => ({
//   position: 'relative',
//   height: '100vh',
//   backgroundColor: theme.palette.grey[900],
//   overflow: 'hidden',
// }));

// const StyledStageContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
//   width: '100%',
//   [theme.breakpoints.up('md')]: {
//     flexDirection: 'row',
//   },
// }));

// const StyledStage = styled(motion.div)(({ theme }) => ({
//   position: 'relative',
//   overflow: 'hidden',
//   flex: 1,
//   height: '33.33vh',
//   [theme.breakpoints.up('md')]: {
//     height: '100vh',
//   },
// }));

// const StyledImage = styled('img')({
//   width: '100%',
//   height: '100%',
//   objectFit: 'cover',
//   transition: 'transform 0.3s',
//   '&:hover': {
//     transform: 'scale(1.1)',
//   },
// });

// const StyledOverlay = styled(motion.div)(({ theme }) => ({
//   position: 'absolute',
//   inset: 0,
//   background: 'linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.3))',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   padding: theme.spacing(2),
// }));

// const StyledContent = styled(motion.div)(({ theme }) => ({
//   backgroundColor: 'rgba(255,255,255,0.1)',
//   backdropFilter: 'blur(8px)',
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(3),
//   maxWidth: '20rem',
//   width: '100%',
// }));

// const StyledIcon = styled(Box)(({ theme }) => ({
//   width: 64,
//   height: 64,
//   marginBottom: theme.spacing(2),
//   marginLeft: 'auto',
//   marginRight: 'auto',
//   color: theme.palette.primary.main,
// }));

// const StyledIndicator = styled(motion.div)(({ theme, active }) => ({
//   width: 12,
//   height: 12,
//   borderRadius: '50%',
//   backgroundColor: theme.palette.common.white,
//   opacity: active ? 1 : 0.5,
// }));

//end of styled components which i installed

const milestones = [
  {
    year: 2020,
    month: "September",
    event: "AvancerPI Founded",
    video:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Final%20Logo%20Intro%20-%20Avancer-u5hlKqo8JqWA9iCv4MxdhYidAFPsPU.mp4",
  },
  {
    year: 2022,
    event: "Became the Platinum Services Partner to Nutanix",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-8o8FeSEulYtU9lbPPY8W4rCLdjlrET.png",
  },
  {
    year: 2023,
    event: "Awarded Outstanding Service Partner for Nutanix",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ccXNjqmPIjPqzD1LKlaObXr4Pg8CEq.png",
  },
  {
    year: 2024,
    month: "February",
    event: "Opened new office at Wheelers Road Bengaluru",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ym0BVDiH0EpWicSUcAtlqc1O8R4p9D.png",
  },
  {
    year: 2024,
    month: "October",
    event: "Best Nutanix Services Partner [APAC]",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-BOdtOFHauS1fOt93YAwnS6k4YD5QjL.png",
  },
];

const certifications = Array(40)
  .fill(null)
  .map((_, index) => ({
    name: `Certification ${index + 1}`,
    image: `/cert${(index % 6) + 1}.jpg`,
  }));

export default function Component() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentCertPage, setCurrentCertPage] = useState(0);
  const [activeStage, setActiveStage] = useState(null);
  const [hoverArrow, setHoverArrow] = useState(null);

  const certContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Tailwind's md breakpoint is 768px
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const nextCertPage = () => {
    setCurrentCertPage(
      (prev) => (prev + 1) % Math.ceil(certifications.length / 12)
    );
  };

  const prevCertPage = () => {
    setCurrentCertPage(
      (prev) =>
        (prev - 1 + Math.ceil(certifications.length / 12)) %
        Math.ceil(certifications.length / 12)
    );
  };

  return (
    <div className="min-h-screen w-full overflow-x-hidden bg-gray-50">
      {/* Header */}
      <HeaderNav />
      {/* Hero Section (Enhanced NEOM-style) */}
      <section className="relative h-screen bg-gray-900 overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col md:flex-row h-full w-full mt-20 mb-4 bg-gradient-to-b rounded-lg from-black/70 to-black/30 px-4 py-6 sm:px-6 sm:py-8">
            {stages.map((stage, index) => (
              <motion.div
                key={stage.title}
                className={`relative overflow-hidden mb-4 md:mb-0 md:flex-1 md:space-x-4`}
                initial={{ flex: 1 }}
                animate={
                  !isMobile
                    ? {
                        flex: activeStage === index ? 2 : 1,
                        transition: { duration: 0.5, ease: "easeInOut" },
                      }
                    : {}
                }
                onHoverStart={() => setActiveStage(index)}
                onHoverEnd={() => setActiveStage(null)}
              >
                <motion.div
                  className="absolute inset-0 flex flex-col items-center justify-center md:p-4 p-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.div
                    className="bg-white/10 backdrop-blur-md rounded-xl p-6 max-w-md w-full space-y-4"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <motion.div
                      className="text-white flex items-center md:flex-col text-center space-y-2"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.4 }}
                    >
                      <div>
                        <stage.icon className="h-10 w-10 md:h-16 md:w-16 mx-auto text-blue-400" />
                      </div>
                      <h2 className="text-2xl ml-3 sm:text-3xl md:text-4xl font-bold mb-1">
                        {stage.title}
                      </h2>
                    </motion.div>
                    <motion.p
                      className="text-sm sm:text-base text-gray-200 text-center"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.5 }}
                    >
                      {stage.description}
                    </motion.p>
                  </motion.div>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="absolute bottom-10 left-0 right-0 justify-center space-x-2 px-4 hidden sm:flex">
          {stages.map((_, index) => (
            <motion.div
              key={index}
              className={`w-3 h-3 rounded-full bg-white ${
                activeStage === index ? "opacity-100" : "opacity-50"
              }`}
              initial={false}
              animate={{ scale: activeStage === index ? 1.2 : 1 }}
              transition={{ duration: 0.3 }}
            />
          ))}
        </div>
      </section>

      {/* Our Story and Vision Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="md:w-1/2 mb-8 md:mb-0 md:pr-8"
            >
              <h2 className="text-4xl font-bold mb-6">Our Story</h2>
              <p className="text-lg text-gray-600 mb-4">
                Founded in September 2020, AvancerPI has been at the forefront
                of digital transformation, helping businesses navigate the
                complex landscape of technology. Our journey began with a simple
                mission: to empower organizations through innovative IT
                solutions.
              </p>
              <p className="text-lg text-gray-600">
                Over the years, we've grown from a small team of passionate
                technologists to a trusted partner in delivering cutting-edge
                professional services, driving success for each customer through
                tailored solutions and unwavering commitment.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="md:w-1/2 bg-gray-100 rounded-lg p-8"
            >
              <h2 className="text-3xl font-bold mb-6">Our Vision & Mission</h2>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2 flex items-center">
                  <Eye className="mr-2 text-blue-600" />
                  Vision
                </h3>
                <p className="text-gray-600">
                  To be the global leader in innovative technology solutions,
                  driving digital transformation and empowering businesses to
                  thrive in the digital age.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2 flex items-center">
                  <Crosshair className="mr-2 text-blue-600" />
                  Mission
                </h3>
                <p className="text-gray-600">
                  We are committed to delivering cutting-edge IT solutions that
                  solve complex business challenges, foster innovation, and
                  create lasting value for our clients. Through our expertise,
                  integrity, and passion for technology, we aim to be the
                  trusted partner in every organization's digital journey.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {/* Our Values Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={value.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-lg p-6"
              >
                <value.icon className="h-12 w-12 text-blue-600 mb-4 mx-auto" />
                <h3 className="text-xl font-semibold mb-2 text-center">
                  {value.title}
                </h3>
                <p className="text-gray-600 text-center">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      {/* Our Expertise Section */}
      {/* <section className="py-20 bg-white overflow-hidden">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">
            Our Expertise
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.name}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.8,
                  delay: index * 0.1,
                  type: "spring",
                  stiffness: 100,
                }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.2 },
                }}
                className="bg-gray-100 rounded-lg p-8 transform-gpu"
              >
                <motion.div
                  whileHover={{
                    scale: 1.2,
                    rotate: 360,
                    transition: { duration: 0.8, type: "spring" },
                  }}
                >
                  <service.icon className="h-12 w-12 text-blue-600 mb-6 mx-auto" />
                </motion.div>
                <h3 className="text-xl font-semibold mb-4 text-center">
                  {service.name}
                </h3>
                <p className="text-gray-600 text-center">
                  {service.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section> */}
      <OurExperties />
      {/* Our Journey Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Our Journey</h2>
          <div className="relative">
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-blue-600"></div>
            {milestones.map((milestone, index) => (
              <motion.div
                key={milestone.year + milestone.event}
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="flex items-center mb-8"
              >
                <div
                  className={`w-5/12 ${
                    index % 2 === 0 ? "text-right pr-8" : "pl-8"
                  }`}
                >
                  <div className="bg-white rounded-lg shadow-lg p-4">
                    <h3 className="text-lg font-semibold mb-1">
                      {milestone.month
                        ? `${milestone.month} ${milestone.year}`
                        : milestone.year}
                    </h3>
                    <p className="text-gray-600 text-sm">{milestone.event}</p>
                  </div>
                </div>
                <div className="w-2/12 flex justify-center">
                  <div className="w-4 h-4 bg-blue-600 rounded-full"></div>
                </div>
                <div className="w-5/12">
                  {milestone.video ? (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                      className="relative"
                    >
                      <video
                        src={milestone.video}
                        className="rounded-lg shadow-lg w-full h-auto"
                        controls
                      />
                    </motion.div>
                  ) : (
                    milestone.image && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className="relative"
                      >
                        <img
                          src={milestone.image}
                          alt={milestone.event}
                          width={800}
                          height={600}
                          className="rounded-lg shadow-lg w-full h-auto object-contain"
                        />
                      </motion.div>
                    )
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      {/* Our Certifications Section */}
      <CertificationShowcase />
      {/* CTA Section */}
      <section className="py-20 bg-blue-600 text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-4xl font-bold mb-6">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl mb-8">
              Partner with AvancerPI and leverage our expertise to drive your
              digital transformation journey.
            </p>
            <Link to="/ContactUs">
              <button
                size="lg"
                variant="secondary"
                className="bg-white py-2 px-4 rounded-xl text-blue-600 hover:bg-gray-100"
              >
                Get in Touch
              </button>
            </Link>
          </motion.div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
}
