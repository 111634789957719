import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ServicesPage from "../Services";
import { Link } from "react-router-dom";
import CustomerSuccessStory from "../SuccessStory/CustomerSuccessStory";
import {
  ArrowLeft,
  ArrowRight,
  Clock,
  DollarSign,
  Shield,
  Server,
  Cloud,
  ShieldIcon,
  ChevronRight,
} from "lucide-react";

const Button = ({ children, asChild, size, className, ...props }) => (
  <button
    className={`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background ${
      size === "lg" ? "h-11 px-8 py-2" : "h-10 py-2 px-4"
    } ${className}`}
    {...props}
  >
    {asChild ? children : <span>{children}</span>}
  </button>
);

const slides = [
  {
    id: 1,
    type: "award",
    title: "Nutanix Services Partner Award Winner",
    subtitle: "APAC FY24",
    description:
      "We are proud to be recognized as the leading Nutanix services partner in the APAC region for FY24, demonstrating our commitment to excellence and innovation in IT services.",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-sH8Z20ZkOxAosCZZfygLWDe791Kpr5.png",
  },
  {
    id: 2,
    type: "success",
    title: "Revolutionizing IT for India's Leading Energy Pioneer",
    subtitle:
      "Transforming private cloud operations across Nutanix and VMware platforms with advanced automation and governance.",
    stats: [
      { icon: Clock, value: "95%", label: "Faster Operations" },
      { icon: DollarSign, value: "30%", label: "Cost Savings" },
      { icon: Shield, value: "100%", label: "Governance Compliance" },
    ],
    description:
      "We helped one of India's top energy companies modernize their infrastructure with a private cloud solution spanning Nutanix and VMware. By automating Day 1 and Day 2 operations and implementing business process workflows, we delivered complete control and scalability for their critical operations.",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/WhatsApp%20Image%202024-11-16%20at%2019.41.19_e42dec86.jpg-0pEOHjU4BqAdguUJQ0SncWFc152gaE.jpeg",
    cta: {
      text: "Know About Our Success Stories",
      link: "/solutions",
    },
  },
  {
    id: 3,
    type: "capabilities",
    title: "Driving Digital Transformation with Proven Expertise",
    subtitle:
      "Explore our core capabilities designed to transform your IT infrastructure and enable business growth.",
    capabilities: [
      {
        icon: Server,
        title: "Data Center Modernization",
        description: "Optimize and future-proof your infrastructure.",
      },
      {
        icon: Cloud,
        title: "Cloud Automations",
        description:
          "Streamline processes and reduce costs with advanced automation.",
      },
      {
        icon: ShieldIcon,
        title: "Business Continuity",
        description:
          "Ensure operational resilience with disaster recovery solutions.",
      },
    ],
    cta: {
      text: "Explore All Services",
      link: "/ServicesPage",
    },
  },
  {
    id: 4,
    type: "WeAreHiring",
  },
];

const jobPositions = [
  {
    title: "Senior Consultant – HCI and Network Virtualization",
    description:
      "Design, deploy, and optimize cutting-edge IT infrastructures.",
  },
  {
    title: "Migration Lead – On-Prem and Cloud Transformations",
    description: "Guide successful transitions to modern cloud environments.",
  },
  {
    title: "Project Manager – Driving Professional Services & Innovation",
    description: "Lead transformative IT projects to successful completion.",
  },
  {
    title: "HR Specialist – Empowering Our Growing Team",
    description: "Foster talent and build a world-class IT consulting team.",
  },
];

export default function HeroSlider() {
  const [currentJobIndex, setCurrentJobIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentJobIndex((prevIndex) => (prevIndex + 1) % jobPositions.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isAnimating) {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }
    }, 7000);

    return () => clearInterval(timer);
  }, [isAnimating]);

  const nextSlide = () => {
    if (!isAnimating) {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }
  };

  const prevSlide = () => {
    if (!isAnimating) {
      setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    }
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      Index: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };

  const renderSlideContent = (slide) => {
    switch (slide.type) {
      case "award":
        return (
          <div className="relative pt-10 min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-[#006D77] to-[#83C5BE]">
            {/* Animated Background */}
            <div className="absolute inset-0 overflow-hidden">
              <svg
                className="absolute w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="dots"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="1" fill="rgba(255,255,255,0.3)" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#dots)" />
              </svg>
              <motion.div
                className="absolute inset-0 opacity-50"
                animate={{ backgroundPosition: ["0% 0%", "100% 100%"] }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 20,
                }}
                style={{
                  backgroundImage:
                    'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="..." fill="rgba(255,255,255,0.1)" fill-rule="evenodd"/%3E%3C/svg%3E")',
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-[30%_70%] gap-8 items-center p-4 md:p-6 relative z-10">
              {/* Text Section */}
              <div className="space-y-6">
                <motion.h1
                  className="text-3xl md:text-5xl font-bold text-white drop-shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  {slide.title}
                </motion.h1>
                <motion.p
                  className="text-lg md:text-2xl text-white drop-shadow"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  {slide.subtitle}
                </motion.p>
                <motion.p
                  className="text-lg md:text-base text-teal-100"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  {slide.description}
                </motion.p>
              </div>

              {/* Image Section */}
              <motion.div
                className="relative w-full h-auto md:h-[600px]"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3 }}
              >
                <img
                  src={slide.image}
                  alt="Nutanix Services Partner Award"
                  className="object-contain w-full h-full rounded-lg shadow-lg"
                />
              </motion.div>
            </div>
          </div>
        );

      case "success":
        return (
          <div className="relative pt-10 min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500">
            {/* Animated background */}
            <div className="absolute inset-0 overflow-hidden">
              <svg
                className="absolute w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="dots"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="1" fill="rgba(255,255,255,0.3)" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#dots)" />
              </svg>
              <motion.div
                className="absolute inset-0 opacity-50"
                animate={{ backgroundPosition: ["0% 0%", "100% 100%"] }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 20,
                }}
                style={{
                  backgroundImage:
                    'url("data:image/svg+xml,%3Csvg width=100%3E%3Cpath d=.../%3E%3C/svg%3E")',
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>

            <div className="grid grid-cols-1 md:gap-8 items-center p-4 md:py-10 md:p-6 z-10">
              <div className="space-y-0 md:space-y-8 py-6 md:py-10 pt-8 md:pt-12">
                <div className="space-y-4 mb-4">
                  <motion.h1
                    className="text-2xl sm:text-3xl md:text-5xl font-bold text-white"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    {slide.title}
                  </motion.h1>
                  <motion.p
                    className="text-sm sm:text-lg md:text-xl text-white"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    {slide.subtitle}
                  </motion.p>
                </div>
                <motion.div
                  className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  {slide.stats.map((stat, index) => (
                    <div
                      key={index}
                      className="bg-white/30 rounded-lg p-4 sm:p-6 space-y-2 sm:space-y-4 hover:bg-white/50 transition-colors"
                    >
                      <div className="flex flex-row md:flex-col items-center md:items-start space-x-3 md:space-x-0 md:space-y-2">
                        <stat.icon className="h-6 w-6 sm:h-8 sm:w-8 text-white" />
                        <div className="md:text-left">
                          <div className="text-lg sm:text-2xl font-bold text-white">
                            {stat.value}
                          </div>
                          <div className="text-xs sm:text-sm text-white/80">
                            {stat.label}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </motion.div>
                <div className="py-4">
                  <div>
                    <motion.p
                      className="sd:mt-4 mt-2 text-white/80 max-w-3xl mx-auto text-sm sm:text-base md:text-lg"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5 }}
                    >
                      {slide.description}
                    </motion.p>
                  </div>
                  <div className="mt-4">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      <Button
                        asChild
                        size="lg"
                        className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full transition duration-300 hover:bg-teal-100 hover:text-teal-700"
                      >
                        <Link
                          to="/CustomerSuccessStory"
                          className="flex items-center justify-center sm:justify-start"
                        >
                          {slide.cta.text}
                          <ChevronRight className="ml-2 h-4 w-4" />
                        </Link>
                      </Button>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "capabilities":
        return (
          <div className="relative pt-10 min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-emerald-500 via-teal-600 to-cyan-600">
            {/* Animated Background */}
            <div className="absolute inset-0 overflow-hidden">
              <svg
                className="absolute w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="dots"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="1" fill="rgba(255,255,255,0.3)" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#dots)" />
              </svg>
              <motion.div
                className="absolute inset-0 opacity-50"
                animate={{ backgroundPosition: ["0% 0%", "100% 100%"] }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 20,
                }}
                style={{
                  backgroundImage:
                    'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="..." fill="rgba(255,255,255,0.1)" fill-rule="evenodd"/%3E%3C/svg%3E")',
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>

            <div className="space-y-8 p-6 md:p-10 relative z-10">
              {/* Title Section */}
              <div className="space-y-4">
                <motion.h1
                  className="text-3xl md:text-6xl font-bold text-white md:text-left drop-shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  {slide.title}
                </motion.h1>
                <motion.p
                  className="text-lg md:text-xl text-white md:text-left drop-shadow"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  {slide.subtitle}
                </motion.p>
              </div>

              {/* Capabilities Section */}
              <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                {slide.capabilities.map((capability, index) => (
                  <div
                    key={index}
                    className="bg-white/20 rounded-lg p-4 md:p-6 hover:bg-white/30 transition-colors"
                  >
                    <div className="flex flex-row md:flex-col items-center md:items-start space-x-3 md:space-x-0 md:space-y-2">
                      <capability.icon className="h-8 w-8 md:h-10 md:w-10 text-teal-100" />
                      <h3 className="text-lg md:text-xl font-semibold text-white text-center md:text-left">
                        {capability.title}
                      </h3>
                    </div>
                    <p className="text-white mt-2 md:mt-4 text-sm md:text-base">
                      {capability.description}
                    </p>
                  </div>
                ))}
              </motion.div>

              {/* Call-to-Action Button */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <Button
                  asChild
                  size="lg"
                  className="bg-white text-teal-600 font-bold py-3 px-6 rounded-full transition duration-300 hover:bg-teal-100 hover:text-teal-700"
                >
                  <Link to="/Services" className="flex items-center">
                    {slide.cta.text}
                    <ChevronRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
              </motion.div>
            </div>
          </div>
        );

      case "WeAreHiring":
        return (
          <section className="relative pt-10 min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-teal-500 to-white">
            {/* Animated background */}
            <div className="absolute inset-0 overflow-hidden">
              <svg
                className="absolute w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="dots"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="1" fill="rgba(255,255,255,0.3)" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#dots)" />
              </svg>
              <motion.div
                className="absolute inset-0 opacity-50"
                animate={{
                  backgroundPosition: ["0% 0%", "100% 100%"],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 20,
                }}
                style={{
                  backgroundImage:
                    'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="..." fill="rgba(255,255,255,0.1)" fill-rule="evenodd"/%3E%3C/svg%3E")',
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>

            <div className="container mx-auto px-4 z-10">
              <div className="max-w-4xl mx-auto text-center">
                <motion.h1
                  className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-white drop-shadow-lg"
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  We&apos;re Hiring! Shape the Future of IT with AvancerPI
                  Solutions
                </motion.h1>
                <motion.p
                  className="text-lg sm:text-xl md:text-2xl mb-6 text-white drop-shadow"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  Explore exciting roles in cloud solutions, automation, and IT
                  transformation.
                </motion.p>

                {/* Job Carousel */}
                <div className="mb-8 h-32">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={currentJobIndex}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                      className="text-white"
                    >
                      <h2 className="text-2xl sm:text-3xl font-bold mb-2">
                        {jobPositions[currentJobIndex].title}
                      </h2>
                      <p className="text-lg sm:text-xl">
                        {jobPositions[currentJobIndex].description}
                      </p>
                    </motion.div>
                  </AnimatePresence>
                </div>

                {/* CTA */}
                <div className="mb-12">
                  <h3 className="text-xl sm:text-2xl font-bold text-white mb-4">
                    Be Part of Our Global Team. Apply Today!
                  </h3>
                  <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <Link
                      to="/Hiring"
                      className="bg-white text-teal-600 font-bold py-3 px-6 rounded-full transition duration-300 hover:bg-teal-100 hover:text-teal-700 flex items-center"
                    >
                      View Open Positions
                      <ChevronRight className="ml-2 h-5 w-5" />
                    </Link>
                    <Link
                      to="/ContactUs"
                      className="bg-teal-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 hover:bg-teal-700 flex items-center"
                    >
                      Join Us Now
                      <ChevronRight className="ml-2 h-5 w-5" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
    }
  };

  return (
    <div className="relative h-screen bg-gray-900 overflow-hidden">
      {/* <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-gray-900/20 via-gray-900/5 to-transparent" />
      </div> */}

      <div className="relative h-full p-6">
        <AnimatePresence initial={false} custom={currentSlide}>
          <motion.div
            key={slides[currentSlide].id}
            custom={currentSlide}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            className="absolute inset-0 flex items-center bg-gray-900"
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            <div className="container mx-auto">
              {renderSlideContent(slides[currentSlide])}
            </div>
          </motion.div>
        </AnimatePresence>

        <button
          onClick={prevSlide}
          className="absolute z-40 left-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-slate-700/30 hover:bg-slate-700/50 transition-colors"
        >
          <ArrowLeft className="h-6 w-6 text-slate-100" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute z-40 right-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-slate-700/30 hover:bg-slate-700/50 transition-colors"
        >
          <ArrowRight className="h-6 w-6 text-slate-100" />
        </button>

        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                index === currentSlide ? "bg-slate-400" : "bg-slate-700"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
