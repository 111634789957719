import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Box,
  CircularProgress
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { baseURL } from '../index';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function CandidateManagement() {
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openResume, setOpenResume] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [noCandidates, setNoCandidates] = useState(false);
  const [openTextBox, setOpenTextBox] = useState({});
  const [textBoxContent, setTextBoxContent] = useState({});
  const [isTextBoxLocked, setIsTextBoxLocked] = useState({});
  const [allCandidatesPoints, setAllCandidatesPoints] = useState('');
  const [isAllCandidatesLocked, setIsAllCandidatesLocked] = useState(false);
  const [openAllCandidatesDialog, setOpenAllCandidatesDialog] = useState(false);
  const [hoveredCandidate, setHoveredCandidate] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, candidateId: null });
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  async function getToken() {
    try {
      const response = await axios.post(
        "/api/login", // Replace with the correct login endpoint
        {
          email: "admin@avancerpi.com",
          password: "Avancer@123",
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      return response.data.jwtToken;
    } catch (error) {
      console.error("Error fetching token:", error.response?.data || error.message);
      throw error;
    }
  }

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const token = await getToken();
      const [candidatesResponse, allCandidatesResponse] = await Promise.all([
        axios.get(`/api/job_application`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        }),
        axios.get("/api/job_application/allCanidates?appid=forall", {
          headers:{
            Authorization : token,
            "Content-Type": "application/json"
          }
        })
      ]);
    
      if (candidatesResponse.status !== 200) {
        throw new Error('Failed to fetch candidates');
      }

      if (allCandidatesResponse.status !== 200){
        throw new Error('Failed to fetch All Candidate data');
      }
      setAllCandidatesPoints(allCandidatesResponse.data.content)

      const result = candidatesResponse.data;

      if (result.data.length === 0) {
        setNoCandidates(true);
      } else {
        const candidatesWithStatus = result.data.map(candidate => ({ ...candidate }));
        setCandidates(candidatesWithStatus);
        setFilteredCandidates(candidatesWithStatus);
        setNoCandidates(false);

        const initialTextBoxState = {};
        const initialTextBoxContent = {};
        const initialLockState = {};

        candidatesWithStatus.forEach(candidate => {
          initialTextBoxState[candidate.appid] = false;
          initialTextBoxContent[candidate.appid] = '';
          initialLockState[candidate.appid] = false;
        });

        setOpenTextBox(initialTextBoxState);
        setTextBoxContent(initialTextBoxContent);
        setIsTextBoxLocked(initialLockState);

        // Extract unique roles
        const uniqueRoles = [...new Set(candidatesWithStatus.map(candidate => candidate.role))];
        setRoles(uniqueRoles);
      }
    } catch (err) {
      setError('An error occurred while fetching the candidates. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (id, newStatus) => {
    const updatedCandidates = candidates.map(candidate => 
      candidate.appid === id ? {...candidate, status: newStatus} : candidate
    );
    setCandidates(updatedCandidates);
    setFilteredCandidates(updatedCandidates);
  };

  const handleSubmit = async (id) => {
    const candidate = candidates.find(c => c.appid === id);
    if (!candidate) return;
    try {
      const token = await getToken();
      const response = await axios.put('/api/job_application', {
        appid: id,
        status: candidate.status
      }, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to update candidate status');
      }
  
      toast.success('Candidate status updated successfully!');
    } catch (err) {
      console.error('Error updating candidate status:', err);
      toast.error('Failed to update candidate status');
    }
  };

  const handleOpenResume = (resumeName) => {
    const url = `${baseURL}uploads/${resumeName}.pdf`;
    window.open(url, '_blank');
  };

  const handleCloseResume = () => {
    setOpenResume(null);
  };

  const handleDownloadResume = (resume, candidateName) => {
    const blob = new Blob([new Uint8Array(resume.data.data)], { type: resume.contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${candidateName}_resume.pdf`;
    link.click();
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = candidates.filter(candidate => 
        candidate.name.toLowerCase().includes(value.toLowerCase()) ||
        candidate.email.toLowerCase().includes(value.toLowerCase()) ||
        candidate.appid.toLowerCase().includes(value.toLowerCase()) ||
        candidate.phone.includes(value) ||
        candidate.status.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCandidates(filtered);
    } else {
      setFilteredCandidates(candidates);
    }
  };

  const handleOpenTextBox = async (id) => {
    setOpenTextBox(prev => ({ ...prev, [id]: true }));
    await fetchContent(id);
  };

  const handleCloseTextBox = (id) => {
    setOpenTextBox(prev => ({ ...prev, [id]: false }));
    setTextBoxContent(prev => ({ ...prev, [id]: '' }));
    setIsTextBoxLocked(prev => ({ ...prev, [id]: false }));
  };

  const handleTextBoxChange = (id, value) => {
    if (!isTextBoxLocked[id]) {
      setTextBoxContent(prev => ({ ...prev, [id]: value }));
    }
  };

  const handleToggleLock = (id) => {
    setIsTextBoxLocked(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSendData = async (id, email) => {
    try {
      const token = await getToken();
      const response = await axios.put('/api/job_application/addPoints_candidate', {
        appid: id,
        email: email,
        content: textBoxContent[id],
      },{
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
      
      if (response.status !== 200) {
        throw new Error('Failed to send data');
      }
  
      toast.success('Data sent successfully!');
      handleCloseTextBox(id);
    } catch (err) {
      console.error('Error sending data:', err);
      toast.error('Failed to send data');
    }
  };

  const fetchContent = async (appid) => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `/api/job_application/addPoints_candidate`,
        {
          params: { appid },
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        }
      );
      const data = response.data?.data?.[0]?.content || '';
      setTextBoxContent((prev) => ({
        ...prev,
        [appid]: data,
      }));
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  const handleAllCandidatesPointsChange = (event) => {
    if (!isAllCandidatesLocked) {
      setAllCandidatesPoints(event.target.value);
    }
  };

  const handleToggleAllCandidatesLock = () => {
    setIsAllCandidatesLocked(!isAllCandidatesLocked);
  };

  const handleSendAllCandidatesData = async () => {
    try {
      const token = await getToken();
      const response = await axios.put('/api/job_application/allCanidates', {
        content: allCandidatesPoints 
      }, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
      if (response.status !== 200) {
        throw new Error('Failed to send data');
      }
      toast.success('Data sent successfully for all candidates!');
      setIsAllCandidatesLocked(false);
      setOpenAllCandidatesDialog(false);
      // Fetch the updated data after sending
      const updatedDataResponse = await axios.get("/api/job_application/allCanidates?appid=forall", {
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
      setAllCandidatesPoints(updatedDataResponse.data.content || '');
    } catch (err) {
      console.error('Error sending data:', err);
      toast.error('Failed to send data for all candidates');
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getToken();
      const response = await axios.delete(`/api/job_application`, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        data: { appid: id },
      });
      if (response.status !== 200) {
        throw new Error('Failed to delete candidate');
      }
      toast.success('Candidate deleted successfully!');
      setCandidates(candidates.filter(c => c.appid !== id));
      setFilteredCandidates(filteredCandidates.filter(c => c.appid !== id));
    } catch (err) {
      console.error('Error deleting candidate:', err);
      toast.error('Failed to delete candidate');
    }
    setDeleteConfirmation({ open: false, candidateId: null });
  };

  const handleRoleChange = (event) => {
    const role = event.target.value;
    setSelectedRole(role);
    if (role) {
      const filtered = candidates.filter(candidate => candidate.role === role);
      setFilteredCandidates(filtered);
    } else {
      setFilteredCandidates(candidates);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Card sx={{ maxWidth: 400 }}>
          <CardHeader title={<Typography variant="h5" color="error" align="center">Error</Typography>} />
          <CardContent>
            <Typography align="center">{error}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (noCandidates) { 
    return <Typography align="center" sx={{ mt: 2 }}>No applicants found.</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 2 }}>
      <Card>
        <CardHeader 
          title="Candidate List"
          action={
            <Button variant="contained" onClick={() => setOpenAllCandidatesDialog(true)}>
              Add Points for All Candidates
            </Button>
          }
        />
        <CardContent>
          <Box display="flex" gap={2} mb={2}>
            <TextField
              fullWidth
              placeholder="Search Candidates by email, name, phone or status"
              value={searchTerm}
              onChange={handleSearch}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <Select
                value={selectedRole}
                onChange={handleRoleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Filter by role' }}
              >
                <MenuItem value="">
                  <em>All Roles</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Application ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Resume</TableCell>
                  <TableCell>Add Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCandidates.map((candidate) => (
                  <TableRow 
                    key={candidate.appid}
                    onMouseEnter={() => setHoveredCandidate(candidate.appid)}
                    onMouseLeave={() => setHoveredCandidate(null)}
                  >
                    <TableCell>
                      <Typography>{candidate.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {candidate.role || 'Role not specified'}
                      </Typography>
                    </TableCell>
                    <TableCell>{candidate.email}</TableCell>
                    <TableCell>{candidate.phone}</TableCell>
                    <TableCell>{candidate.appid}</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={candidate.status || 'pending'}
                          onChange={(e) => handleStatusChange(candidate.appid, e.target.value)}
                        >
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="rejected">Rejected</MenuItem>
                          <MenuItem value="approved">Approved</MenuItem>
                        </Select>
                      </FormControl>
                      {candidate.status === 'rejected' && hoveredCandidate === candidate.appid && (
                        <IconButton
                          onClick={() => setDeleteConfirmation({ open: true, candidateId: candidate.appid })}
                          size="small"
                          sx={{ marginLeft: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleSubmit(candidate.appid)}>
                        Submit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handleOpenResume(candidate.email)}>
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handleOpenTextBox(candidate.appid)}>
                        Add Points
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {filteredCandidates.length === 0 && (
            <Typography align="center" color="textSecondary" sx={{ mt: 2 }}>
              No candidates found.
            </Typography>
          )}
        </CardContent>
      </Card>

      <Dialog open={openResume !== null} onClose={handleCloseResume} maxWidth="md" fullWidth>
        <DialogTitle>
          Resume
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => handleDownloadResume(openResume, 'candidate')}
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {openResume && (
            <Document file={{ data: new Uint8Array(openResume.data.data) }}>
              <Page pageNumber={1} />
            </Document>
          )}
        </DialogContent>
      </Dialog>

      {candidates.map((candidate) => (
        <Dialog
          key={candidate.appid}
          open={openTextBox[candidate.appid] || false}
          onClose={() => handleCloseTextBox(candidate.appid)}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              minHeight: '300px',
            },
          }}
        >
          <DialogTitle>Add Points for {candidate.name}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={8}
              value={textBoxContent[candidate.appid] || ''}
              onChange={(e) => handleTextBoxChange(candidate.appid, e.target.value)}
              disabled={isTextBoxLocked[candidate.appid]}
              margin="normal"
              sx={{
                fontSize: '1.2rem',
              }}
            />
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => handleToggleLock(candidate.appid)}>
              {isTextBoxLocked[candidate.appid] ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
            <Button onClick={() => handleSendData(candidate.appid, candidate.email)}>Send Data</Button>
          </DialogActions>
        </Dialog>
      ))}

      <Dialog
        open={openAllCandidatesDialog}
        onClose={() => setOpenAllCandidatesDialog(false)}
        fullWidth 
        maxWidth="md"
        sx={{
          '& .MuiDialog-paper': { 
            minHeight: '500px',
          },
        }}
      >
        <DialogTitle>Add Points for All Candidates</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={14}
            value={allCandidatesPoints}
            onChange={handleAllCandidatesPointsChange}
            disabled={isAllCandidatesLocked}
            margin="normal"
            sx={{
              fontSize: '1.2rem',
            }}
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleToggleAllCandidatesLock}>
            {isAllCandidatesLocked ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
          <Button onClick={handleSendAllCandidatesData}>Send Data</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, candidateId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this candidate? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation({ open: false, candidateId: null })}>Cancel</Button>
          <Button onClick={() => handleDelete(deleteConfirmation.candidateId)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

