import React, { useEffect, useRef } from "react";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Cloud,
  Server,
  Zap,
  Clock,
  CheckCircle,
  ChevronRight,
  Network,
  Users,
  Database,
  Smartphone,
} from "lucide-react";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Bimage from "../../assets/SuccessStories/GlobalTelecomSolutionsProviderSuccessStory.png";

const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2, suffix = "" }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      <span>{suffix}</span>
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

const GlobalTelecomSolutionsProviderSuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <div className="min-h-screen bg-white">
        {/* Hero Section */}
        <section className="relative h-[500px] md:min-h-screen bg-gray-900 flex items-end justify-center overflow-hidden">
          {/* Background Image */}
          <img
            src={Bimage}
            alt="Global Telecom Solutions Provider"
            className="absolute inset-0 w-full h-full object-cover z-0"
          />

          {/* Overlay Animation */}
          <motion.div
            className="absolute inset-0 z-0"
            animate={{
              backgroundColor: [
                "rgba(0,0,0,0.3)",
                "rgba(0,0,0,0.5)",
                "rgba(0,0,0,0.3)",
              ],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />

          {/* Content Section */}
          <div className="relative z-10 container  mx-auto px-4 py-8">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="max-w-4xl mx-auto text-center"
            >
              {/* Title */}
              <div className="bg-black bg-opacity-50  rounded-lg p-6">
                <motion.h1
                  className="text-3xl md:text-5xl lg:text-6xl  font-bold mb-6 text-white [text-shadow:_0_2px_10px_rgb(0_0_0_/_20%)]"
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Simplifying Operations and Accelerating Performance with
                  Nutanix Private Cloud
                </motion.h1>

                {/* Description */}
                <motion.p
                  className="text-lg md:text-xl lg:text-2xl mb-8 text-blue-100 [text-shadow:_0_2px_10px_rgb(0_0_0_/_20%)]"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                >
                  Empowering a global telecom leader with cutting-edge private
                  cloud solutions
                </motion.p>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Business Need and Challenges
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  A leading global telecom solutions provider faced significant
                  challenges with OpenStack management and troubleshooting,
                  resulting in extended recovery times and limited support.
                  Their IT team spent considerable time resolving issues,
                  impacting overall productivity. The customer sought a solution
                  to migrate workloads efficiently and improve operational
                  management through a robust private cloud platform.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-center group">
                    <Server className="mr-2 text-blue-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      OpenStack management and troubleshooting complexities
                    </span>
                  </li>
                  <li className="flex items-center group">
                    <Clock className="mr-2 text-blue-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      Extended recovery times for issues
                    </span>
                  </li>
                  <li className="flex items-center group">
                    <Users className="mr-2 text-blue-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      IT team productivity impacted by constant troubleshooting
                    </span>
                  </li>
                  <li className="flex items-center group">
                    <Database className="mr-2 text-blue-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      Need for efficient workload migration and improved
                      operational management
                    </span>
                  </li>
                </ul>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #3B82F6 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #8B5CF6 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #3B82F6 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Nutanix Cluster Deployment",
                  description:
                    "Deployed a secure and enterprise-grade data center on the Nutanix platform.",
                  icon: Server,
                },
                {
                  title: "Migration Services",
                  description:
                    "Seamlessly migrated 300 VMs from OpenStack and KVM to Nutanix AHV within 3-4 weeks with minimal downtime.",
                  icon: Cloud,
                },
                {
                  title: "Private Cloud Setup",
                  description:
                    "Set up a Private Cloud with complete governance and developed blueprints for frequently deployed operating systems.",
                  icon: Network,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-blue-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Business Benefits */}
        <motion.section
          id="impact"
          className="py-20 bg-white"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-900">
                Business Benefits
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
              {[
                { value: 300, label: "VMs Migrated in 3-4 Weeks", icon: Cloud },
                {
                  value: 0,
                  label: "Data Loss During Migration",
                  icon: Database,
                },
                {
                  value: 50,
                  label: "Productivity Boost for IT Teams",
                  icon: Users,
                  suffix: "%",
                },
                {
                  value: "",
                  label: "Significant Reduction in Recovery Time",
                  icon: Clock,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="text-center bg-gray-50 p-6 rounded-lg shadow-lg"
                >
                  <item.icon className="w-12 h-12 mx-auto mb-4 text-blue-600" />
                  <div className="text-3xl md:text-4xl font-bold mb-2 text-gray-900">
                    {item.value !== "" ? (
                      <AnimatedCounter
                        target={item.value}
                        suffix={item.suffix}
                      />
                    ) : (
                      <CheckCircle className="w-12 h-12 mx-auto text-blue-600" />
                    )}
                  </div>
                  <p className="text-sm md:text-base text-gray-600">
                    {item.label}
                  </p>
                </GlowingCard>
              ))}
            </div>
            <motion.div
              className="text-base md:text-lg text-center max-w-3xl mx-auto text-gray-600"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-gray-900">
                Key Achievements:
              </h3>
              <ul className="list-disc list-inside space-y-2 text-left">
                <li>
                  Enhanced Productivity: Smoother management and operations
                  allowed IT team to focus on strategic tasks.
                </li>
                <li>
                  Rapid Migration: Successfully migrated 300 VMs in record time,
                  minimizing operational disruptions.
                </li>
                <li>
                  Simplified Operations: Nutanix AHV reduced troubleshooting
                  complexities, ensuring faster issue resolution and better
                  support.
                </li>
                <li>
                  Optimized Cloud Infrastructure: Private cloud governance and
                  blueprints improved efficiency and ensured compliance.
                </li>
              </ul>
            </motion.div>
          </div>
        </motion.section>

        {/* Testimonial Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-900">
                Client Testimonial
              </h2>
            </ParallaxSection>
            <GlowingCard className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto">
              <p className="text-lg md:text-xl italic mb-4 text-gray-600">
                "The migration to Nutanix and the implementation of our new
                private cloud infrastructure has transformed our IT operations.
                We've seen a dramatic improvement in our team's productivity and
                our ability to respond to business needs quickly and
                efficiently."
              </p>
              <p className="text-base md:text-lg font-semibold text-gray-900">
                – IT Head, Global Telecom Solutions Provider
              </p>
            </GlowingCard>
          </div>
        </motion.section>

        {/* Call to Action Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #3B82F6 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #8B5CF6 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #3B82F6 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 text-center relative z-10">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">
              Discover how we simplify cloud management for seamless operations
            </h2>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link
                to="/ContactUs"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 inline-flex items-center"
              >
                Contact Our Experts
                <ChevronRight className="ml-2 h-5 w-5" />
              </Link>
            </motion.div>
          </div>
        </motion.section>
      </div>
      <Footer />
    </>
  );
};

export default GlobalTelecomSolutionsProviderSuccessStory;
