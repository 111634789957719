import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Logo from "../assets/AvancerPILogoWhite.svg";
import { MapPinned } from "lucide-react";
import XIcon from "@mui/icons-material/X";
import {
  Mail,
  Phone,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
} from "lucide-react";
export default function Footer({ scrollToTop }) {
  const socialLinks = [
    { icon: Linkedin, href: "https://www.linkedin.com/company/avancerpi/" },
    { icon: XIcon, href: "#" },
    { icon: Facebook, href: "#" },
    { icon: Instagram, href: "#" },
    { icon: Youtube, href: "#" },
  ];
  return (
    <div>
      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
            <div className="col-span-2 md:col-span-1">
              <Link to="/" className="flex items-center">
                <img
                  src={Logo}
                  alt="AvancerPI Logo"
                  width={150}
                  height={40}
                  className="mb-6"
                  onClick={scrollToTop}
                />
              </Link>

              {/* <p className="text-gray-400 text-sm">
                Empowering businesses through innovative technology solutions.
              </p> */}
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Company</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/About"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Services"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ContactUs"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="mailto:hello@avancerpi.com"
                    className="flex items-center text-gray-400 hover:text-white transition-colors"
                  >
                    <Mail className="h-4 w-4 mr-2" />
                    hello@avancerpi.com
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://wa.me/919902693693"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-gray-400 hover:text-white transition-colors"
                  >
                    <Phone className="h-4 w-4 mr-2" />
                    +91 9902693693
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.google.com/maps/place/AvancerPI+Solutions(OPC)+Private+Limited/@12.9964324,77.617792,21z/data=!4m6!3m5!1s0x3bae17e837bf6e3f:0xd8bbb6a100cce9f9!8m2!3d12.9963924!4d77.6176616!16s%2Fg%2F11lw4bb2q4?authuser=0&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-gray-400 hover:text-white transition-colors"
                  >
                    <MapPinned className="h-4 w-4 mr-2" />
                    AvancerPI Solutions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex flex-col items-start space-y-4">
              <h3 className="text-lg font-semibold">Follow Us</h3>
              <div className="flex space-x-4">
                {socialLinks.map((social, index) => (
                  <motion.a
                    key={index}
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition-colors"
                    whileHover={{
                      scale: 1.2,
                      transition: {
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      },
                    }}
                  >
                    <social.icon className="h-6 w-6" />
                  </motion.a>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="border-t border-gray-800 pt-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-gray-400 text-sm">
                &copy; {new Date().getFullYear()} AvancerPI. All rights
                reserved.
              </p>
              <div className="flex space-x-6 md:mt-0">
                {[
                  { icon: Linkedin, href: "https://www.linkedin.com/company/avancerpi/" },
                  { icon: XIcon, href: "#" },
                  { icon: Facebook, href: "#" },
                  { icon: Instagram, href: "#" },
                  { icon: Youtube, href: "#" },
                ].map((social, index) => (
                  <motion.a
                    key={index}
                    to={social.to}
                    className="text-gray-400 hover:text-white transition-colors"
                    whileHover={{
                      scale: 1.2,
                      transition: {
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      },
                    }}
                  >
                    <social.icon className="h-6 w-6" />
                  </motion.a>
                ))}
              </div>
            </div>
          </div> */}

          <div className="border-t border-gray-800 pt-8">
            <div className="flex flex-col md:flex-col items-center">
              <p className="text-gray-400 text-sm">
                &copy; {new Date().getFullYear()} AvancerPI. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
