"use client";

import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

import HeaderNav from "../../screens/HeaderNav";
import Footer from "../../screens/Footer";
import { Link } from "react-router-dom";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionTrigger,
//   AccordionContent,
// } from "@radix-ui/react-accordion";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  ChevronDown,
  Server,
  Database,
  Cloud,
  Shield,
  Code,
  Laptop,
  Box,
  Cpu,
  Settings,
  Cog,
  Zap,
  Users,
  Target,
  Award,
  ThumbsUp,
  Menu,
  X,
  Phone,
  Mail,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  ArrowRight,
  ChevronRight,
  GitMerge,
  Lock,
  Plug,
  Workflow,
  GitBranch,
  MoveRight,
  CheckCircle,
  RefreshCw,
  Book,
} from "lucide-react";
import Button from "../../components/button";
// import {
//   Card,
//   CardContent,
//   CardDescription,
//   CardHeader,
//   CardTitle,
// } from "@/components/ui/card";
// import {
//   Accordion,
//   AccordionContent,
//   AccordionItem,
//   AccordionTrigger,
// } from "@/components/ui/accordion";

const services = [
  {
    name: "Data Center & Operations",
    icon: Server,
    subServices: [
      { name: "Virtualization", icon: Box },
      { name: "HCI", icon: Database },
      { name: "Management", icon: Settings },
      { name: "Operations", icon: Cog },
      { name: "Infrastructure Automation", icon: Zap },
    ],
  },
  {
    name: "Workload Migrations",
    icon: Laptop,
    subServices: [
      { name: "Migration Strategy", icon: Target },
      { name: "P2V", icon: Box },
      { name: "V2V", icon: Cpu },
      { name: "X2V", icon: Zap },
      { name: "X2C", icon: Cloud },
    ],
  },
  {
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    subServices: [
      { name: "Local & Remote Availability", icon: Server },
      { name: "RPO, RTO, SLA", icon: Target },
      { name: "Runbooks", icon: Book },
      { name: "Failover & Failback", icon: RefreshCw },
      { name: "DR Automation", icon: Zap },
    ],
  },
  {
    name: "Database Modernization",
    icon: Database,
    subServices: [
      { name: "Database as a Service", icon: Cloud },
      { name: "Database Migrations", icon: MoveRight },
      { name: "Database Fit Checks", icon: CheckCircle },
      { name: "Database Automations", icon: Zap },
    ],
  },
  {
    name: "Cloud Automations",
    icon: Cloud,
    subServices: [
      { name: "Private Cloud", icon: Lock },
      { name: "Hybrid Cloud", icon: GitMerge },
      { name: "3rd Party Integrations", icon: Plug },
      { name: "Application Automations", icon: Code },
      { name: "Process Automations", icon: Workflow },
    ],
  },
  {
    name: "App Modernization",
    icon: Code,
    subServices: [
      { name: "Containerization", icon: Box },
      { name: "Kubernetes Platform", icon: Cpu },
      { name: "CI/CD Pipelines", icon: GitBranch },
      { name: "Cloud-Native Development", icon: Cloud },
    ],
  },
];

const subServices = [
  {
    name: "Virtualization",
    icon: Box,
    description:
      "Optimize resource utilization and enhance flexibility with our cutting-edge virtualization solutions.",
    benefits: [
      "Reduced hardware costs",
      "Improved scalability",
      "Enhanced disaster recovery",
    ],
  },
  {
    name: "HCI (Hyper-Converged Infrastructure)",
    icon: Database,
    description:
      "Simplify your IT infrastructure with our integrated computing, storage, and networking solutions.",
    benefits: [
      "Streamlined management",
      "Increased agility",
      "Cost-effective scaling",
    ],
  },
  {
    name: "Management",
    icon: Settings,
    description:
      "Gain complete control and visibility of your data center operations with our advanced management tools.",
    benefits: [
      "Centralized control",
      "Real-time monitoring",
      "Predictive maintenance",
    ],
  },
  {
    name: "Operations",
    icon: Cog,
    description:
      "Streamline your data center operations for maximum efficiency and minimal downtime.",
    benefits: [
      "Optimized workflows",
      "Reduced operational costs",
      "Improved service delivery",
    ],
  },
  {
    name: "Infrastructure Automation",
    icon: Zap,
    description:
      "Automate routine tasks and processes to boost productivity and reduce human error.",
    benefits: [
      "Faster deployments",
      "Consistent configurations",
      "Freed up IT resources",
    ],
  },
];

const teamValues = [
  {
    icon: Users,
    title: "Expert Team",
    description:
      "Our seasoned professionals bring years of industry experience to every project.",
  },
  {
    icon: Target,
    title: "Outcome-Focused",
    description:
      "We deliver solutions that drive tangible business results and ROI.",
  },
  {
    icon: Award,
    title: "Design Excellence",
    description:
      "Our solutions are built on proven design principles and best practices.",
  },
  {
    icon: ThumbsUp,
    title: "Consultative Approach",
    description:
      "We work closely with you to understand and meet your unique needs.",
  },
];

const faqs = [
  {
    question:
      "What are the main benefits of modernizing our data center operations?",
    answer:
      "Modernizing your data center operations can lead to increased efficiency, reduced costs, improved scalability, enhanced security, and better overall performance. It allows your business to adapt quickly to changing needs and take advantage of new technologies.",
  },
  {
    question:
      "How long does it typically take to implement virtualization in our existing infrastructure?",
    answer:
      "The timeline for implementing virtualization can vary depending on the size and complexity of your infrastructure. Generally, it can take anywhere from a few weeks to several months. We work closely with you to create a phased approach that minimizes disruption to your operations.",
  },
  {
    question:
      "Is it possible to integrate our legacy systems with new data center technologies?",
    answer:
      "Yes, it's often possible to integrate legacy systems with modern data center technologies. We specialize in creating hybrid solutions that allow you to leverage your existing investments while gradually transitioning to more modern, efficient systems.",
  },
  {
    question:
      "How can infrastructure automation improve our data center's security?",
    answer:
      "Infrastructure automation can significantly enhance security by ensuring consistent application of security policies, rapid deployment of security patches, and real-time monitoring and response to potential threats. It reduces the risk of human error in security-related tasks and allows for faster incident response.",
  },
];

export default function DataCenterOperationsPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [hoveredService, setHoveredService] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <HeaderNav />

      {/* Main Content */}
      <main className="pt-20">
        {/* Hero Section */}
        <section className="relative bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20 overflow-hidden">
          <div className="container mx-auto px-4 relative z-10">
            <motion.h1
              className="text-4xl font-bold mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Data Center & Operations
            </motion.h1>
            <motion.p
              className="text-xl mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Optimize your infrastructure for peak performance, scalability,
              and reliability.
            </motion.p>
          </div>
          <motion.div
            className="absolute inset-0 bg-black opacity-10"
            animate={{
              backgroundPosition: ["0% 0%", "100% 100%"],
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
        </section>

        {/* Services Section */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Our Data Center & Operations Services
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {subServices.map((service, index) => (
                <div
                  key={service.name}
                  className="h-full border-2 p-4 rounded-xl transition-all duration-300 hover:shadow-lg"
                >
                  <div>
                    <div className="flex items-center space-x-2">
                      <service.icon className="h-6 w-6 text-blue-600" />
                      <span>{service.name}</span>
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-600 mb-4">
                      {service.description}
                    </div>
                    <ul className="list-disc list-inside text-gray-600">
                      {service.benefits.map((benefit, index) => (
                        <li key={index} className="mb-1">
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Team Values Section */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Why Choose Our Data Center & Operations Services?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {teamValues.map((value, index) => (
                <motion.div
                  key={value.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className="h-full bg-white p-6 rounded-lg border-2 transition-all duration-300 hover:shadow-lg">
                    <div>
                      <div className="flex flex-col items-center text-center">
                        <motion.div
                          whileHover={{ scale: 1.1, rotate: 360 }}
                          transition={{ duration: 0.3 }}
                        >
                          <value.icon className="h-12 w-12 text-blue-600 mb-4" />
                        </motion.div>
                        <span>{value.title}</span>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">{value.description}</div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg p-8">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="mb-6 md:mb-0">
                  <h2 className="text-3xl font-bold mb-2">
                    Ready to Optimize Your Data Center?
                  </h2>
                  <p className="text-xl">
                    Let's discuss how we can transform your operations.
                  </p>
                </div>
                <button
                  size="lg"
                  variant="secondary"
                  className="bg-white rounded-lg px-4 py-2 text-blue-600 hover:bg-gray-100 transition-colors duration-300"
                >
                  Get Started
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Frequently Asked Questions
            </h2>
            <div style={{ maxWidth: "768px", margin: "0 auto" }}>
              {faqs.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
