import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

// Generate 40 dummy certification objects

import Badge1 from "../../assets/CrtificationBadges/str1.png";
import Badge2 from "../../assets/CrtificationBadges/str2.png";
import Badge3 from "../../assets/CrtificationBadges/str3.png";
import Badge4 from "../../assets/CrtificationBadges/str4.png";
import Badge5 from "../../assets/CrtificationBadges/str5.png";
import Badge6 from "../../assets/CrtificationBadges/str6.png";
import Badge7 from "../../assets/CrtificationBadges/str7.png";
import Badge8 from "../../assets/CrtificationBadges/str8.png";
import Badge9 from "../../assets/CrtificationBadges/str9.png";
import Badge10 from "../../assets/CrtificationBadges/str10.png";
import Badge11 from "../../assets/CrtificationBadges/str11.png";
import Badge12 from "../../assets/CrtificationBadges/str12.png";
import Badge13 from "../../assets/CrtificationBadges/str13.png";
import Badge14 from "../../assets/CrtificationBadges/str14.png";
import Badge15 from "../../assets/CrtificationBadges/str15.png";
import Badge16 from "../../assets/CrtificationBadges/str16.png";
import Badge17 from "../../assets/CrtificationBadges/str17.png";
import Badge18 from "../../assets/CrtificationBadges/str18.png";
import Badge19 from "../../assets/CrtificationBadges/str19.png";
import Badge20 from "../../assets/CrtificationBadges/str20.png";
import Badge21 from "../../assets/CrtificationBadges/str21.png";
import Badge22 from "../../assets/CrtificationBadges/str22.png";
import Badge23 from "../../assets/CrtificationBadges/str23.png";
import Badge24 from "../../assets/CrtificationBadges/str24.png";
import Badge25 from "../../assets/CrtificationBadges/str25.png";
import Badge26 from "../../assets/CrtificationBadges/str26.png";
import Badge27 from "../../assets/CrtificationBadges/str27.png";
import Badge28 from "../../assets/CrtificationBadges/str28.png";
import Badge29 from "../../assets/CrtificationBadges/str29.png";
import Badge30 from "../../assets/CrtificationBadges/str30.png";
import Badge31 from "../../assets/CrtificationBadges/str31.png";
import Badge32 from "../../assets/CrtificationBadges/str32.png";
import Badge33 from "../../assets/CrtificationBadges/str33.png";
import Badge34 from "../../assets/CrtificationBadges/str34.png";
import Badge35 from "../../assets/CrtificationBadges/str35.png";

const Badges = [
  Badge1,
  Badge2,
  Badge3,
  Badge4,
  Badge5,
  Badge6,
  Badge7,
  Badge8,
  Badge9,
  Badge10,
  Badge11,
  Badge12,
  Badge13,
  Badge14,
  Badge15,
  Badge16,
  Badge17,
  Badge18,
  Badge19,
  Badge20,
  Badge21,
  Badge22,
  Badge23,
  Badge24,
  Badge25,
  Badge26,
  Badge27,
  Badge28,
  Badge29,
  Badge30,
  Badge31,
  Badge32,
  Badge33,
  Badge34,
  Badge35,
];

const certifications = Array.from({ length: 35 }, (_, i) => ({
  id: i + 1,
  name: `Certification ${i + 1}`,
  image: Badges[i],
}));

const CertificationShowcase = () => {
  const [currentSet, setCurrentSet] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const totalSets = Math.ceil(certifications.length / 15);
  const containerRef = useRef(null);
  const controls = useAnimation();
  const timerRef = useRef(null);

  const startRotation = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      if (!isPaused) {
        setCurrentSet((prevSet) => (prevSet + 1) % totalSets);
      }
    }, 5000);
  };

  useEffect(() => {
    startRotation();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [totalSets, isPaused]);

  useEffect(() => {
    controls.start({
      backgroundPositionX: ["0%", "100%"],
      transition: { duration: 20, ease: "linear", repeat: Infinity },
    });
  }, [controls]);

  const currentCertifications = certifications.slice(
    currentSet * 15,
    (currentSet + 1) * 15
  );

  return (
    <motion.div
      ref={containerRef}
      className="min-h-screen relative overflow-hidden py-16 px-4"
      initial={{
        background:
          "linear-gradient(45deg, #1a202c, #2d3748, #4a5568, #2d3748, #1a202c)",
      }}
      animate={controls}
      style={{ backgroundSize: "400% 100%" }}
    >
      <div className="absolute inset-0 opacity-30">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full bg-yellow-300"
            style={{
              width: Math.random() * 10 + 5 + "px",
              height: Math.random() * 10 + 5 + "px",
              left: Math.random() * 100 + "%",
              top: Math.random() * 100 + "%",
            }}
          />
        ))}
      </div>

      <div className="max-w-7xl mx-auto relative z-10">
        <motion.h2
          className="text-5xl font-extrabold text-center text-white mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Our Achievements
        </motion.h2>
        <div className="relative w-full h-[600px]">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSet}
              className="grid grid-cols-3 md:grid-cols-5 gap-4 absolute inset-0"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.2 }}
              transition={{ duration: 0.5 }}
            >
              {currentCertifications.map((cert, index) => (
                <motion.div
                  key={cert.id}
                  className="flex items-center justify-center"
                  initial={{ opacity: 0, rotateY: 90 }}
                  animate={{ opacity: 1, rotateY: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.5 }}
                >
                  <motion.div
                    className="relative w-24 h-24 md:w-32 md:h-32 cursor-pointer perspective-1000"
                    whileHover={{ scale: 1.2, zIndex: 10 }}
                    onHoverStart={() => setIsPaused(true)}
                    onHoverEnd={() => setIsPaused(false)}
                  >
                    <motion.div
                      className="w-full h-full transition-all duration-500 preserve-3d"
                      // whileHover={{ rotateY: 180 }}
                    >
                      <div className="absolute inset-0 w-full h-full backface-hidden">
                        <div className="absolute inset-0 bg-white backdrop-filter backdrop-blur-sm rounded-lg shadow-lg" />
                        <img
                          src={cert.image}
                          alt={cert.name}
                          className="w-full h-full object-contain p-2 relative z-10"
                        />
                      </div>
                      <div className="absolute inset-0 w-full h-full backface-hidden rotate-y-180 bg-gray-800 rounded-lg flex items-center justify-center p-2">
                        <span className="text-white text-xs md:text-sm text-center">
                          {cert.name}
                        </span>
                      </div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      <style jsx global>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .preserve-3d {
          transform-style: preserve-3d;
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
      `}</style>
    </motion.div>
  );
};

export default CertificationShowcase;
