import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function ProtectedRoute({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const validateUser = async () => {
      const token = localStorage.getItem("jwtToken");

      if (!token) {
        toast.error("JWT Token is required for authentication");
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get("/api/authenticate", {
          headers: {
            Authorization: token,
          },
        });

        if (response.status === 200) {
          console.log("User authenticated");
        } else {
          throw new Error("Invalid authentication response");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Authentication failed");
        navigate("/login");
      }
    };

    validateUser();
  }, [navigate]);

  return <>{children}</>;
}

export default ProtectedRoute;
