import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/AvancerPILogoDarkBlue.svg";
import WhiteLogo from "../assets/AvancerPILogoWhite.svg";

import {
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  Menu,
  X,
  Server,
  Database,
  Cloud,
  Shield,
  Settings,
  Code,
  Laptop,
  Mail,
  Phone,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
  Users,
  BarChart,
  Box,
  Cpu,
  GitBranch,
  Rocket,
  ArrowUpCircle,
  Shuffle,
  HardDrive,
  Network,
  Zap,
  Layers,
  Workflow,
  FileCode,
  RefreshCw,
  Clock,
  FileCheck,
  Repeat,
  ChevronUp,
  Cog,
} from "lucide-react";

import Button from "../components/button";

const services = [
  {
    name: "Datacenter & Operations",
    icon: Server,
    image: "/datacenter-ops.jpg",
    subServices: [
      { name: "Virtualization", icon: Box },
      { name: "HCI", icon: Cpu },
      { name: "Management", icon: Settings },
      { name: "Operations", icon: Cog },
      { name: "Infrastructure Automation", icon: Zap },
    ],
  },
  {
    name: "Workload Migrations",
    icon: Laptop,
    image: "/workload-migration.jpg",
    subServices: [
      { name: "Migration Strategy & Execution", icon: GitBranch },
      { name: "P2V", icon: ArrowUpCircle },
      { name: "V2V", icon: Shuffle },
      { name: "X2V", icon: RefreshCw },
      { name: "X2C", icon: Cloud },
    ],
  },
  {
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    image: "/business-continuity.jpg",
    subServices: [
      { name: "Local & Remote site availability", icon: Network },
      { name: "RPO, RTO, SLA", icon: Clock },
      { name: "Runbooks", icon: FileCheck },
      { name: "Failover & Failback", icon: Repeat },
      { name: "DR Runbook & Automations", icon: Workflow },
    ],
  },
  {
    name: "Database Modernization",
    icon: Database,
    image: "/database-modern.jpg",
    subServices: [
      { name: "Database as a Service", icon: Cloud },
      { name: "Database Migrations", icon: Shuffle },
      { name: "Database Fitchecks", icon: FileCheck },
      { name: "Database Automations", icon: Zap },
    ],
  },
  {
    name: "Cloud Automations",
    icon: Cloud,
    image: "/cloud-auto.jpg",
    subServices: [
      { name: "Private Cloud", icon: Shield },
      { name: "Hybrid Cloud", icon: Layers },
      { name: "3rd Party Integrations", icon: Workflow },
      { name: "Application Automations", icon: Zap },
      { name: "Process Automations", icon: Cog },
    ],
  },
  {
    name: "App Modernization",
    icon: Code,
    image: "/app-modern.jpg",
    subServices: [
      { name: "Containerization", icon: Box },
      { name: "Kubernetes platform", icon: Cpu },
      { name: "Build & Adapt existing CI/CD Pipelines", icon: GitBranch },
      { name: "Modernize or Build cloud-native Apps", icon: Rocket },
    ],
  },
];

export default function Header({ scrollToTop }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeService, setActiveService] = useState("Datacenter & Operations");
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedKey, setSelectedKey] = useState(null);

  const [isSideOpen, setIsSideOpen] = useState(false);
  const togglesideDropdown = () => setIsSideOpen(!isSideOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSideOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const value
  const handleServiceNames = (serserviceName) => {
    alert(serserviceName);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleServiceHover = (serviceName) => {
    setActiveService(serviceName);
  };

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const [expandedService, setExpandedService] = useState(
    "Datacenter & Operations"
  );
  const [selectedSubService, setSelectedSubService] = useState(null);

  const toggleDropdown = () => setIsSideOpen(!isSideOpen);

  const toggleService = (servName) => {
    setExpandedService(expandedService === servName ? null : servName);
  };

  const handleSubServiceClick = (subService) => {
    setSelectedSubService(subService);
    // You can add additional logic here, such as navigation or displaying more information
    console.log(`Selected sub-service: ${subService.name}`);
  };

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div>
      <header
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? "bg-white shadow-md" : "bg-black/70 backdrop-blur-sm"
        }`}
      >
        <nav className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center">
              {isScrolled ? (
                <img
                  src={Logo}
                  className="h-8 w-auto"
                  alt="AvancerPI Logo"
                  onClick={scrollToTop}
                />
              ) : (
                <img
                  src={WhiteLogo}
                  className="h-8 w-auto"
                  alt="AvancerPI Logo"
                  onClick={scrollToTop}
                />
              )}
            </Link>
            <div className="hidden md:flex items-center space-x-8">
              <Link
                to="/Home"
                className={`transition-colors ${
                  isScrolled
                    ? "text-gray-700 hover:text-blue-600"
                    : "text-white hover:text-blue-300"
                }`}
                onMouseEnter={() => handleDropdownToggle(null)}
              >
                Home
              </Link>
              <Link
                to="/About"
                className={`transition-colors ${
                  isScrolled
                    ? "text-gray-700 hover:text-blue-600"
                    : "text-white hover:text-blue-300"
                }`}
                onMouseEnter={() => handleDropdownToggle(null)}
              >
                About
              </Link>
              <div
                className="relative"
                onMouseEnter={() => handleDropdownToggle("services")}
              >
                <button
                  className={`flex items-center transition-colors ${
                    isScrolled
                      ? "text-gray-700 hover:text-blue-600"
                      : "text-white hover:text-blue-300"
                  }`}
                >
                  Services
                  <ChevronDown className="ml-1 h-4 w-4" />
                </button>
              </div>
              <Link
                to="/ContactUs"
                className={`transition-colors ${
                  isScrolled
                    ? "text-gray-700 hover:text-blue-600"
                    : "text-white hover:text-blue-300"
                }`}
                onMouseEnter={() => handleDropdownToggle(null)}
              >
                Contact Us
              </Link>
            </div>
            <Button
              variant="ghost"
              className={`md:hidden`}
              onClick={() => setIsSideOpen(!isSideOpen)}
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </Button>
          </div>
        </nav>
      </header>
      <AnimatePresence>
        {activeDropdown === "services" && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 flex items-start justify-center"
            onClick={(e) => {
              if (e.target === e.currentTarget)
                handleDropdownToggle("services");
            }}
          >
            <motion.div
              ref={dropdownRef}
              className="bg-white rounded-lg shadow-xl mt-20 w-[95%] max-w-7xl overflow-hidden"
              onMouseLeave={() => handleDropdownToggle("services")}
            >
              <div className="flex">
                <div className="w-1/3 border-r border-gray-200 py-4">
                  {services.map((service) => (
                    <motion.div
                      key={service.name}
                      // onClick={() => handleServiceNames(service.name)}
                      onMouseEnter={() => handleServiceHover(service.name)}
                      className={`px-4 py-3 cursor-pointer transition-colors ${
                        activeService === service.name ? "bg-gray-100" : ""
                      }`}
                    >
                      <div
                        className="flex items-center space-x-3"
                        key={selectedKey}
                      >
                        <service.icon className="h-5 w-5 text-blue-600" />
                        <Link
                          to={
                            activeService === "Datacenter & Operations"
                              ? "/Services/Data"
                              : activeService === "Workload Migrations"
                              ? "/Services/Workload"
                              : activeService ===
                                "Business Continuity & Disaster Recovery"
                              ? "/Services/Business"
                              : activeService === "Database Modernization"
                              ? "/Services/Database"
                              : activeService === "Cloud Automations"
                              ? "/Services/Cloud"
                              : activeService === "App Modernization"
                              ? "/Services/App"
                              : ""
                          }
                        >
                          <span className="text-sm font-medium">
                            {service.name}
                          </span>
                        </Link>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="w-2/3 py-4 px-6">
                  {services.map((service) => (
                    <AnimatePresence key={service.name}>
                      {activeService === service.name && (
                        <motion.div
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -20 }}
                          className="space-y-4"
                        >
                          <h3 className="text-lg font-semibold text-gray-900">
                            {service.name}
                          </h3>
                          <div className="grid grid-cols-2 gap-4">
                            {service.subServices.map((subService) => (
                              <Link
                                key={subService.name}
                                to={
                                  activeService === "Datacenter & Operations"
                                    ? "/Services/Data"
                                    : activeService === "Workload Migrations"
                                    ? "/Services/Workload"
                                    : activeService ===
                                      "Business Continuity & Disaster Recovery"
                                    ? "/Services/Business"
                                    : activeService === "Database Modernization"
                                    ? "/Services/Database"
                                    : activeService === "Cloud Automations"
                                    ? "/Services/Cloud"
                                    : activeService === "App Modernization"
                                    ? "/Services/App"
                                    : ""
                                }
                                className="flex items-center p-2 rounded-md hover:bg-gray-100 transition-colors text-sm text-gray-600 hover:text-blue-600"
                              >
                                <subService.icon className="h-5 w-5 mr-2" />
                                {subService.name}
                              </Link>
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      {isSideOpen && (
        <div className="fixed inset-y-16 right-0 w-full h-[650px] max-w-sm bg-gray-800 shadow-xl overflow-y-auto z-50 rounded-lg border border-gray-700">
          <div className="flex items-center justify-between p-4 border-b border-gray-700 bg-gray-900 rounded-t-lg">
            <h2 className="text-xl font-semibold text-white">Menu</h2>
          </div>
          <div className="p-4 space-y-3">
            {/* Home Button */}
            <Button
              className="w-full flex items-center justify-between text-left mb-2 p-3 h-14 bg-gray-700 text-white rounded-md border border-gray-600 shadow-sm hover:bg-gray-600 transition-all duration-150"
              variant="ghost"
              onClick={() => navigateTo("Home")}
            >
              <span className="truncate flex-grow text-sm font-medium">
                Home
              </span>
            </Button>

            {/* About Us Button */}
            <Button
              className="w-full flex items-center justify-between text-left mb-2 p-3 h-14 bg-gray-700 text-white rounded-md border border-gray-600 shadow-sm hover:bg-gray-600 transition-all duration-150"
              variant="ghost"
              onClick={() => navigateTo("About")}
            >
              <span className="truncate flex-grow text-sm font-medium">
                About Us
              </span>
            </Button>

            {/* Services Dropdown */}
            <div>
              <Button
                className="w-full flex items-center justify-between text-left mb-2 p-3 h-14 bg-gray-700 text-white rounded-md border border-gray-600 shadow-sm hover:bg-gray-600 transition-all duration-150"
                variant="ghost"
                onClick={() => toggleService("Services")}
              >
                <span className="truncate flex-grow text-sm font-medium">
                  Services
                </span>
                {expandedService === "Services" ? (
                  <ChevronUp className="w-5 h-5 text-blue-300" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-blue-300" />
                )}
              </Button>
              {expandedService === "Services" && (
                <ul className="space-y-2 pl-8 mt-2">
                  {services.map((service) => (
                    <li key={service.name}>
                      <Link
                        to={
                          service.name === "Datacenter & Operations"
                            ? "/Services/Data"
                            : service.name === "Workload Migrations"
                            ? "/Services/Workload"
                            : service.name ===
                              "Business Continuity & Disaster Recovery"
                            ? "/Services/Business"
                            : service.name === "Database Modernization"
                            ? "/Services/Database"
                            : service.name === "Cloud Automations"
                            ? "/Services/Cloud"
                            : service.name === "App Modernization"
                            ? "/Services/App"
                            : ""
                        }
                      >
                        <Button
                          variant="ghost"
                          className="w-full flex items-center justify-start text-left p-2 text-sm font-normal text-gray-300 rounded-lg hover:bg-gray-600 transition-all duration-150"
                        >
                          <service.icon className="w-4 h-4 mr-2 text-blue-400" />
                          <span className="truncate">{service.name}</span>
                        </Button>
                      </Link>
                      {expandedService === service.name && (
                        <ul className="space-y-2 pl-8 mt-2">
                          {service.subServices.map((subService) => (
                            <li key={subService.name}>
                              <Button
                                variant="ghost"
                                className="w-full flex items-center justify-start text-left p-2 text-sm font-normal text-gray-300 rounded-lg hover:bg-gray-600 transition-all duration-150"
                                onClick={() =>
                                  handleSubServiceClick(subService)
                                }
                              >
                                <subService.icon className="w-4 h-4 mr-2 text-blue-400" />
                                <span className="truncate">
                                  {subService.name}
                                </span>
                              </Button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Contact Us Button */}
            <Button
              className="w-full flex items-center justify-between text-left mb-2 p-3 h-14 bg-gray-700 text-white rounded-md border border-gray-600 shadow-sm hover:bg-gray-600 transition-all duration-150"
              variant="ghost"
              onClick={() => navigateTo("ContactUs")}
            >
              <span className="truncate flex-grow text-sm font-medium">
                Contact Us
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
