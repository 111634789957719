import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import SlideAwardBanner from "../assets/Home/AwardSlideBanner.jpeg";
import HomeSlider from "../screens/HomeSections/HomeSlider";
import SuccessOne from "../assets/SuccessStories/Success_one.jpg";
import GlobalTelecomSolutionsProviderSuccessStory from "../assets/SuccessStories/GlobalTelecomSolutionsProviderSuccessStory.png";
import IndianCommunicationInnovatorSuccessStory from "../assets/SuccessStories/IndianCommunicationInnovatorSuccessStory.jpg";
import Soudi_leading_bank from "../assets/SuccessStories/Soudi_leading_bank.jpg";
import Global_engineering_powrhose from "../assets/SuccessStories/Global_engineering_powrhose.jpg";
import soudi_digital_leader from "../assets/SuccessStories/soudi_digital_leader.jpg";
import CustomerSuccessStory from "../assets/SuccessStories/CustomerSuccessStory.jpg";
import OurApproach from "../screens/HomeSections/OurApproach";
import { useParams } from "react-router-dom";
import {
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  Menu,
  X,
  Server,
  Database,
  Cloud,
  Shield,
  Settings,
  Code,
  Laptop,
  Mail,
  Phone,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
  Users,
  BarChart,
  Box,
  Cpu,
  GitBranch,
  Rocket,
  ArrowUpCircle,
  Shuffle,
  HardDrive,
  Network,
  Zap,
  Layers,
  Workflow,
  FileCode,
  RefreshCw,
  Clock,
  FileCheck,
  Repeat,
  Cog,
} from "lucide-react";
import axios from "axios";
// import { Button } from "@/components/ui/button";

const services = [
  {
    name: "Datacenter & Operations",
    icon: Server,
    image: "/datacenter-ops.jpg",
    subServices: [
      { name: "Virtualization", icon: Box },
      { name: "HCI", icon: Cpu },
      { name: "Management", icon: Settings },
      { name: "Operations", icon: Cog },
      { name: "Infrastructure Automation", icon: Zap },
    ],
  },
  {
    name: "Workload Migrations",
    icon: Laptop,
    image: "/workload-migration.jpg",
    subServices: [
      { name: "Migration Strategy & Execution", icon: GitBranch },
      { name: "P2V", icon: ArrowUpCircle },
      { name: "V2V", icon: Shuffle },
      { name: "X2V", icon: RefreshCw },
      { name: "X2C", icon: Cloud },
    ],
  },
  {
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    image: "/business-continuity.jpg",
    subServices: [
      { name: "Local & Remote site availability", icon: Network },
      { name: "RPO, RTO, SLA", icon: Clock },
      { name: "Runbooks", icon: FileCheck },
      { name: "Failover & Failback", icon: Repeat },
      { name: "DR Runbook & Automations", icon: Workflow },
    ],
  },
  {
    name: "Database Modernization",
    icon: Database,
    image: "/database-modern.jpg",
    subServices: [
      { name: "Database as a Service", icon: Cloud },
      { name: "Database Migrations", icon: Shuffle },
      { name: "Database Fitchecks", icon: FileCheck },
      { name: "Database Automations", icon: Zap },
    ],
  },
  {
    name: "Cloud Automations",
    icon: Cloud,
    image: "/cloud-auto.jpg",
    subServices: [
      { name: "Private Cloud", icon: Shield },
      { name: "Hybrid Cloud", icon: Layers },
      { name: "3rd Party Integrations", icon: Workflow },
      { name: "Application Automations", icon: Zap },
      { name: "Process Automations", icon: Cog },
    ],
  },
  {
    name: "App Modernization",
    icon: Code,
    image: "/app-modern.jpg",
    subServices: [
      { name: "Containerization", icon: Box },
      { name: "Kubernetes platform", icon: Cpu },
      { name: "Build & Adapt existing CI/CD Pipelines", icon: GitBranch },
      { name: "Modernize or Build cloud-native Apps", icon: Rocket },
    ],
  },
];

const heroSlides = [
  {
    title: "Digital Transformation",
    subtitle: "Empowering businesses through innovative solutions",
    image:
      "https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2072&auto=format&fit=crop",
  },
  {
    title: "Cloud Innovation",
    subtitle: "Scale your business with modern cloud solutions",
    image:
      "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?q=80&w=2034&auto=format&fit=crop",
  },
  {
    title: "Secure Operations",
    subtitle: "Protect your business with advanced security",
    image:
      "https://images.unsplash.com/photo-1486551937199-baf066858de7?q=80&w=2071&auto=format&fit=crop",
  },
  {
    title: "Data Excellence",
    subtitle: "Transform your data into actionable insights",
    image: SlideAwardBanner,
  },
];

const successStories = [
  {
    title:
      "Empowering One of India's Leading Energy Giants with Private Cloud Innovation",
    description: "Modernized core banking infrastructure for 24/7 operations",
    image: CustomerSuccessStory,
    path: "/CustomerSuccessStory",
  },
  {
    title:
      "Transforming Efficiency for a Leading Global Engineering Powerhouse",
    description: "Seamless transition to cloud-based healthcare systems",
    image: Global_engineering_powrhose,
    path: "/GlobalEngineeringPowerhouseSuccessStory",
  },
  {
    title:
      "Empowering India's Leading Communication Innovator with High-Performance Storage Solutions",
    description: "10x performance improvement during peak seasons",
    image: IndianCommunicationInnovatorSuccessStory,
    path: "/IndianCommunicationInnovatorSuccessStory",
  },
  {
    title:
      "Transforming Saudi's Largest Digital Leader with End-to-End Automation Solutions",
    description: "Smart factory implementation with real-time monitoring",
    image: soudi_digital_leader,
    path: "/SaudiDigitalLeaderSuccessStory",
  },
  {
    title: "Empowering Saudi's Top Bank with Advanced Private Cloud Automation",
    description: "Enhanced cybersecurity measures for critical infrastructure",
    image: Soudi_leading_bank,
    path: "/SaudiLeadingBankSuccess",
  },
  {
    title:
      " Simplifying Operations and Accelerating Performance with Nutanix Private Cloud",
    description: "Automated network operations reducing downtime by 90%",
    image: GlobalTelecomSolutionsProviderSuccessStory,
    path: "/GlobalTelecomSolutionsProviderSuccessStory",
  },
];
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling animation
  });
};

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentStorySet, setCurrentStorySet] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [hoverArrow, setHoverArrow] = useState(null);
  const dropdownRef = useRef(null);
  const sliderControls = useAnimation();
  const [storiesPerSet, setStoriesPerSet] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setStoriesPerSet(1);
      } else if (window.innerWidth < 1024) {
        setStoriesPerSet(2);
      } else {
        setStoriesPerSet(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/store_userData", {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
        console.error("Error fetching user data:", error.response?.data || error.message);
      }
    };

    fetchUserData(); // Call the function when the component mounts
  }, []);

  const totalSets = Math.ceil(successStories.length / storiesPerSet);

  const handlePrevious = () => {
    setCurrentStorySet((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentStorySet((prev) => Math.min(prev + 1, totalSets - 1));
  };

  useState(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    const slideInterval = setInterval(() => {
      if (!isHovering) {
        handleSlideChange((currentSlide + 1) % heroSlides.length);
      }
    }, 5000);

    const storyInterval = setInterval(() => {
      setCurrentStorySet((prev) => (prev + 1) % 2);
    }, 5000);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(slideInterval);
      clearInterval(storyInterval);
    };
  }, [isHovering, currentSlide]);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleServiceHover = (serviceName) => {
    setActiveService(serviceName);
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    sliderControls.start({
      x: `${-index * 100}%`,
      transition: { type: "tween", ease: "easeInOut", duration: 0.5 },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <HeaderNav scrollToTop={scrollToTop} />
      {/* Services Dropdown */}
      <AnimatePresence>
        {activeDropdown === "services" && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 flex items-start justify-center"
            onClick={(e) => {
              if (e.target === e.currentTarget)
                handleDropdownToggle("services");
            }}
          >
            <motion.div
              ref={dropdownRef}
              className="bg-white rounded-lg shadow-xl mt-20 w-[95%] max-w-7xl overflow-hidden"
              onMouseLeave={() => handleDropdownToggle("services")}
            >
              <div className="flex">
                <div className="w-1/3 border-r border-gray-200 py-4">
                  {services.map((service) => (
                    <motion.div
                      key={service.name}
                      onMouseEnter={() => handleServiceHover(service.name)}
                      className={`px-4 py-3 cursor-pointer transition-colors ${
                        activeService === service.name ? "bg-gray-100" : ""
                      }`}
                    >
                      <div className="flex items-center space-x-3">
                        <service.icon className="h-5 w-5 text-blue-600" />
                        <span className="text-sm font-medium">
                          {service.name}
                        </span>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="w-2/3 py-4 px-6">
                  {services.map((service) => (
                    <AnimatePresence key={service.name}>
                      {activeService === service.name && (
                        <motion.div
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -20 }}
                          className="space-y-4"
                        >
                          <h3 className="text-lg font-semibold text-gray-900">
                            {service.name}
                          </h3>
                          <div className="grid grid-cols-2 gap-4">
                            {service.subServices.map((subService) => (
                              <Link
                                key={subService.name}
                                to={`/services/${service.name
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}/${subService.name
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}`}
                                className="flex items-center p-2 rounded-md hover:bg-gray-100 transition-colors text-sm text-gray-600 hover:text-blue-600"
                              >
                                <subService.icon className="h-5 w-5 mr-2" />
                                {subService.name}
                              </Link>
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Hero Section with Slider */}
      <HomeSlider />
      {/* Our Approach Section */}
      <OurApproach />
      {/* Services Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <div className="p-6">
                  <motion.div
                    className="mb-4"
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <service.icon className="h-16 w-16 text-blue-600 mx-auto" />
                  </motion.div>
                  <h3 className="text-xl font-semibold text-center mb-2">
                    {service.name}
                  </h3>
                  <motion.div
                    className="mt-4 text-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link
                      to={
                        service.name === "Datacenter & Operations"
                          ? "/Services/Data"
                          : service.name === "Workload Migrations"
                          ? "/Services/Workload"
                          : service.name ===
                            "Business Continuity & Disaster Recovery"
                          ? "/Services/Business"
                          : service.name === "Database Modernization"
                          ? "/Services/Database"
                          : service.name === "Cloud Automations"
                          ? "/Services/Cloud"
                          : service.name === "App Modernization"
                          ? "/Services/App"
                          : ""
                      }
                      className="inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Learn More
                    </Link>
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-blue-600 text-white overflow-hidden">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <motion.h2
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold mb-4"
            >
              Ready to Transform Your Business?
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-xl mb-8"
            >
              Let's discuss how our expertise can help you achieve your
              technology goals.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/ContactUs">
                <button
                  size="lg"
                  variant="secondary"
                  className="bg-white px-6 py-2 rounded-lg text-blue-600 hover:bg-gray-100"
                >
                  Contact Us Today
                </button>
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Success Stories */}
      <section
        className="py-12 md:py-20 bg-white overflow-hidden "
        id="SuccessStories"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12">
            Success Stories
          </h2>
          <motion.div
            className="relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className="flex transition-all duration-500 ease-in-out gap-4 md:gap-8"
              animate={{ x: `${-currentStorySet * 100}%` }}
            >
              {Array.from({ length: totalSets }).map((_, setIndex) => (
                <div key={setIndex} className="flex gap-4 md:gap-8 min-w-full">
                  {successStories
                    .slice(
                      setIndex * storiesPerSet,
                      (setIndex + 1) * storiesPerSet
                    )
                    .map((story, index) => (
                      <Link to={story.path} key={index} className="w-full">
                        <motion.div
                          className="w-full h-[200px] sm:h-[250px] md:h-[300px] bg-white rounded-lg overflow-hidden shadow-lg relative flex-shrink-0"
                          whileHover={{ y: -10, transition: { duration: 0.2 } }}
                        >
                          <img
                            src={story.image}
                            alt={story.title}
                            layout="fill"
                            objectFit="cover"
                          />
                          {/* Overlay for title */}
                          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent">
                            <div className="absolute bottom-0 p-4 text-white">
                              <h3 className="text-lg md:text-xl font-bold">
                                {story.title}
                              </h3>
                            </div>
                          </div>
                        </motion.div>
                      </Link>
                    ))}
                </div>
              ))}
            </motion.div>

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-4 md:mt-8 items-center absolute top-28 left-0 right-0 px-2 md:px-4">
              <Button
                onClick={handlePrevious}
                disabled={currentStorySet === 0}
                variant="outline"
                size="icon"
                className="bg-transparent hover:bg-black/70 backdrop:blur rounded-full"
              >
                <ChevronLeft className="h-8" />
              </Button>
              <Button
                onClick={handleNext}
                disabled={currentStorySet === totalSets - 1}
                variant="outline"
                size="icon"
                className="bg-transparent hover:bg-black/70 backdrop:blur rounded-full"
              >
                <ChevronRight className="h-8" />
              </Button>
            </div>

            {/* Pagination Dots */}
            <div className="flex justify-center mt-4 md:mt-8 space-x-2">
              {Array.from({ length: totalSets }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentStorySet(index)}
                  className={`w-2 h-2 md:w-3 md:h-3 rounded-full transition-all duration-300 ${
                    currentStorySet === index
                      ? "bg-blue-500 w-4 md:w-8"
                      : "bg-cyan-300"
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Footer */}
      <Footer scrollToTop={scrollToTop} />
    </div>
  );
}
