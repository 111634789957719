import React, { useState , useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../../assets/AvancerPI_Symbol.svg";
import {
  Users,
  BarChart3,
  PieChart,
  Activity,
  Mail,
  Clock,
  ArrowUpRight,
  ArrowDownRight,
  Search,
  LogOut,
  FileText,
  Briefcase,
  Settings,
  Bell,
  Shield,
  MessageSquare,
  TrendingUp,
  LineChart,
  Database,
  Eye,
  Clock4,
  ArrowRight,
  MapPin,
  Monitor,
  Tablet,
  SmartphoneIcon as MobileIcon,
  Chrome,
  ChromeIcon as Firefox,
  AppleIcon as Safari,
  FileCheck,
  UserCheck,
  Building2,
  GraduationCap,
  Loader2, 
} from "lucide-react";
import Button from "../../components/button";
import Input from "../../components/Input";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../../components/Card";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuTrigger,
// } from "@/components/ui/dropdown-menu";

// Mock data for different sections

// const [data, setData] = useState([])





const visitorMetrics = {
  devices: [
    { name: "Desktop", value: 45, icon: Monitor },
    { name: "Mobile", value: 40, icon: MobileIcon },
    { name: "Tablet", value: 15, icon: Tablet },
  ],
  browsers: [
    { name: "Chrome", value: 55, icon: Chrome },
    { name: "Firefox", value: 20, icon: Firefox },
    { name: "Safari", value: 25, icon: Safari },
  ],
  topPages: [
    { name: "Home", views: 1250, avgTime: "2m 45s" },
    { name: "Services", views: 980, avgTime: "3m 20s" },
    { name: "Success Stories", views: 850, avgTime: "4m 15s" },
    { name: "Contact", views: 620, avgTime: "1m 55s" },
  ],
};

const jobApplications = {
  total: 450,
  new: 45,
  reviewed: 125,
  shortlisted: 80,
  rejected: 200,
  byRole: [
    { role: "Cloud Engineer", count: 150 },
    { role: "DevOps Engineer", count: 120 },
    { role: "Solution Architect", count: 100 },
    { role: "Database Administrator", count: 80 },
  ],
};

const serviceEngagement = [
  { name: "Cloud Migration", views: 2500, inquiries: 120 },
  { name: "Disaster Recovery", views: 2100, inquiries: 95 },
  { name: "Database Services", views: 1800, inquiries: 85 },
  { name: "DevOps Automation", views: 1600, inquiries: 75 },
];

// Dashboard Sections Components
const DashboardHighlights = () => {

  const [data, setData] = useState({}); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [visitors, setVisitors] = useState(null); 
  const [constactus , setContactus] = useState(null);
  const [jobApp , setJobApp] = useState(null)
  const [newVisitorData, setNewVisitorData] = useState([]);

  const [browserStats , setbrowserStats] = useState([]);
  const [deviceStats , setdeviceStats] = useState([]);
  const [timeSpent , setTimeSpent] = useState([])

  async function getToken() {
    try {
      const response = await axios.post(
        "/api/login", // Replace with the correct login endpoint
        {
          email: "admin@avancerpi.com",
          password: "Avancer@123",
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      return response.data.jwtToken;
    } catch (error) {
      console.error("Error fetching token:", error.response?.data || error.message);
      throw error;
    }
  }
  

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const token = await getToken(); // Assuming this function retrieves the token
        const response = await axios.get("/api/get_UserData", {
          headers: {
            Authorization: token,
          }
        });
        setData(response.data); // Setting the fetched data
        setNewVisitorData(response.data.Visitordata.newVisitorData || [])
        setbrowserStats(response.data.Visitordata.browserStats)
        setdeviceStats(response.data.Visitordata.deviceStats)
        setTimeSpent(response.data.Visitordata.timeSpent)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCandidates(); // Fetch data when component mounts
  }, []);
    
  useEffect(() => {
    const persentageCalculator = async (visitorYesterday , visitorToday) => {
      try {

        if (visitorYesterday === 0) {
          // If there were no visitors yesterday, any visitors today represent a 100% increase
          return visitorToday > 0 ? 100 : 0;
        }

        // Calculate percentage increase
        const percentage = ((visitorToday - visitorYesterday) / visitorYesterday) * 100;

        return percentage.toFixed(2); // Return the percentage rounded to 2 decimal places
      } catch (error) {
        console.error("Error calculating percentage:", error);
        return null; // Return null if an error occurs
      }
    };

    const calculateAndSetVisitors = async () => {
      const visitorRes = await persentageCalculator(data.Visitordata?.visitosYesterday || 0 , data.Visitordata?.newVisitors || 0); 
      setVisitors(visitorRes); 

      const contactusRes = await persentageCalculator(data.ContactUs?.contactAppYesterday || 0 , data.ContactUs?.newContactUsApp || 0);
      setContactus(contactusRes)

      const jonRes = await persentageCalculator(data.JobApplicationData?.jobAppYesterday || 0 , data.JobApplicationData?.newApplicants || 0);
      setJobApp(jonRes)
    };

    calculateAndSetVisitors(); 
  }, [data]); 

    const highlightMetrics = [
      {
        title: "Total Visitors Today",
        value: data.Visitordata?.newVisitors,
        change: `${visitors}%`,
        icon: Users,
        color: "bg-blue-500",
      },
      {
        title: "Avg. Session Duration",
        value: "4m 32s",
        change: "+8.2%",
        icon: Clock,
        color: "bg-green-500",
      },
      {
        title: "Total Job Applications Today",
        value: data.JobApplicationData?.newApplicants,
        change: `${jobApp}%`,
        icon: Briefcase,
        color: "bg-purple-500",
      },
      {
        title: "Total Contact Requests Today",
        value: data.ContactUs?.newContactUsApp,
        change: `${constactus}%`,
        icon: Mail,
        color: "bg-orange-500",
      },
    ];

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {highlightMetrics.map((metric, index) => (
          <motion.div
            key={metric.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card className="p-6 relative overflow-hidden">
              <div
                className={`absolute right-0 top-0 w-24 h-24 -mr-8 -mt-8 rounded-full opacity-10 ${metric.color}`}
              />
              <metric.icon className="h-8 w-8 mb-4 text-muted-foreground" />
              <h3 className="text-sm font-medium text-muted-foreground">
                {metric.title}
              </h3>
              <div className="mt-2 flex items-baseline">
                <p className="text-2xl font-semibold">{metric.value}</p>
                <span className="ml-2 text-sm text-green-500">
                  {metric.change}
                </span>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 gap-6">

      <Card className="p-6">
            <h3 className="font-semibold mb-4">Recent Activity</h3>
            <div className="space-y-4">
              {newVisitorData.map((visitor, index) => (
                <motion.div
                  key={visitor._id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-center space-x-4"
                >
                  <div className="h-2 w-2 rounded-full bg-purple-500" />
                  <div className="flex-1">
                    <p className="text-sm">
                      New visitor from{" "}
                      <span className="font-medium">
                        {visitor.city !== "N/A" ? visitor.city : "Unknown City"}
                      </span>
                      ,{" "}
                      <span className="font-medium">
                        {visitor.country !== "N/A" ? visitor.country : "Unknown Country"}
                      </span>
                    </p>

                    <p className="text-sm text-muted-foreground">
                      {/* Add additional info here if needed */}
                      Viewed the site
                    </p>
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {moment(visitor.createdAt).fromNow()}
                  </div>
                </motion.div>
              ))}
            </div>
      </Card>

        <Card className="p-6">
          <h3 className="font-semibold mb-4">Service Engagement</h3>
          <div className="space-y-4">
            {timeSpent.map((service, index) => (
              <motion.div
                key={service.name}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="space-y-2"
              >
                <div className="flex justify-between text-sm">
                  <span>{service._id}</span>
                  <span>{service.views} views</span>
                </div>
                <div className="h-2 rounded-full bg-muted overflow-hidden">
                  <motion.div
                    className="h-full bg-purple-500"
                    initial={{ width: 0 }}
                    animate={{ width: `${(service.views / 3000) * 100}%` }}
                    transition={{ delay: 0.5 + index * 0.1 }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

const VisitorInsights = () => {

  const [data, setData] = useState({}); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [visitors, setVisitors] = useState(null); 
  const [constactus , setContactus] = useState(null);
  const [jobApp , setJobApp] = useState(null)
  const [newVisitorData, setNewVisitorData] = useState([]);

  const [browserStats , setbrowserStats] = useState([]);
  const [deviceStats , setdeviceStats] = useState([]);
  const [timeSpent , setTimeSpent] = useState([])

  const formatTime = (time) => {
    const totalSeconds = Math.floor(time * 60); // Convert minutes to total seconds
    const hours = Math.floor(totalSeconds / 3600); // Calculate hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate remaining minutes
    const seconds = totalSeconds % 60; // Remaining seconds
  
    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`; // Format as "Hh Mm Ss" if hours exist
    }
    return `${minutes}m ${seconds}s`; // Format as "Mm Ss" otherwise
  };

  async function getToken() {
    try {
      const response = await axios.post(
        "/api/login", // Replace with the correct login endpoint
        {
          email: "admin@avancerpi.com",
          password: "Avancer@123",
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      return response.data.jwtToken;
    } catch (error) {
      console.error("Error fetching token:", error.response?.data || error.message);
      throw error;
    }
  }
  

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const token = await getToken(); // Assuming this function retrieves the token
        const response = await axios.get("/api/get_UserData", {
          headers: {
            Authorization: token,
          }
        });
        setbrowserStats(response.data.Visitordata.browserStats)
        setdeviceStats(response.data.Visitordata.deviceStats)
        setTimeSpent(response.data.Visitordata.timeSpent)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCandidates(); // Fetch data when component mounts
  }, []);

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">

        {/* static data down */}
        {/* <Card className="p-6">
          <h3 className="font-semibold mb-6">Device Distribution</h3>
          <div className="space-y-6">
            {visitorMetrics.devices.map((device, index) => (
              <motion.div
                key={device.name}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <device.icon className="h-5 w-5 mr-4 text-muted-foreground" />
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{device.name}</span>
                    <span>{device.value}%</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${device.value}%` }}
                      transition={{ delay: 0.5 }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card> 
        <Card className="p-6">
          <h3 className="font-semibold mb-6">Browser Usage</h3>
          <div className="space-y-6">
            {visitorMetrics.browsers.map((browser, index) => (
              <motion.div
                key={browser.name}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <browser.icon className="h-5 w-5 mr-4 text-muted-foreground" />
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{browser.name}</span>
                    <span>{browser.value}%</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${browser.value}%` }}
                      transition={{ delay: 0.5 }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card> */}


        {/* Dynamic data down */}
        <Card className="p-6">
          <h3 className="font-semibold mb-6">Device Distribution</h3>
          <div className="space-y-6">
            {deviceStats.map((device, index) => (
              <motion.div
                key={device._id || index} // Use a unique key
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <div className="h-5 w-5 mr-4 bg-gray-300 rounded-full" /> 
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{device._id}</span>
                    <span>{device.count}</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${device.count}%` }}
                      transition={{
                        delay: 0.5 + index * 0.2, // Delay each bar animation by index
                        type: "spring", // Add a spring effect for smooth animation
                        stiffness: 200, // Stiffness of the spring
                        damping: 30, // Damping factor for smoothness
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="font-semibold mb-6">Browser Usage</h3>
          <div className="space-y-6">
            {browserStats.map((browser, index) => (
              <motion.div
                key={browser._id || index} 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <div className="h-5 w-5 mr-4 bg-gray-300 rounded-full" />
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{browser._id}</span>
                    <span>{browser.count}</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${browser.count}%` }}
                      transition={{
                        delay: 0.5 + index * 0.2, // Delay each bar animation by index
                        type: "spring", // Add a spring effect for smooth animation
                        stiffness: 200, // Stiffness of the spring
                        damping: 30, // Damping factor for smoothness
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card>

      </div>

            {/* Staitc data */}
      {/* <Card className="p-6">
        <h3 className="font-semibold mb-6">Most Viewed Pages</h3>
        <div className="space-y-4">
          {visitorMetrics.topPages.map((page, index) => (
            <motion.div
              key={page.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center justify-between p-4 rounded-lg bg-muted/50"
            >
              <div>
                <h4 className="font-medium">{page.name}</h4>
                <p className="text-sm text-muted-foreground">
                  {page.views} views
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium">{page.avgTime}</p>
                <p className="text-sm text-muted-foreground">Avg. Time</p>
              </div>
            </motion.div>
          ))}
        </div>
      </Card> */}

            {/* dynamic data */}
      <Card className="p-6">
        <h3 className="font-semibold mb-6">Most Viewed Pages</h3>
        <div className="space-y-4">
          {timeSpent.map((page, index) => (
            <motion.div
              key={page.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center justify-between p-4 rounded-lg bg-muted/50"
            >
              <div>
                <h4 className="font-medium">{page._id}</h4>
                <p className="text-sm text-muted-foreground">
                  {page.views} views
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium">{formatTime(page.avgTimeSpent)}</p>
                <p className="text-sm text-muted-foreground">Avg. Time</p>
                <p className="text-sm font-medium"> {formatTime(page.totalTimeSpent)}</p>
                <p className="text-sm text-muted-foreground">Total Time</p>
              </div>
            </motion.div>
          ))}
        </div>
      </Card>
    </div>
  );
};

const JobApplicationsView = () => {
  const [jobApp, setJobApp] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [allJobApp , setallJobApp] = useState([]);

  const groupByRole = (applications) => {
    const grouped = applications.reduce((acc, app) => {
      acc[app.role] = acc[app.role] || { count: 0, applications: [] };
      acc[app.role].count += 1;
      acc[app.role].applications.push(app);
      return acc;
    }, {});
    return grouped;
  };

  async function getToken() {
    try {
      const response = await axios.post(
        "/api/login",
        {
          email: "admin@avancerpi.com",
          password: "Avancer@123",
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      return response.data.jwtToken;
    } catch (error) {
      console.error("Error fetching token:", error.response?.data || error.message);
      throw error;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getToken();
        const response = await axios.get("/api/get_UserData", {
          headers: {
            Authorization: token,
          }
        });
        console.log(response.data.JobApplicationData.allApplication , '------------response-----------');
        
        setJobApp(response.data.JobApplicationData);
        setallJobApp(response.data.JobApplicationData.allApplication);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(allJobApp, "Updated allJobApp State");
  }, [allJobApp]);

    const groupedApplications = groupByRole(allJobApp);
    const totalApplications = allJobApp.length;

  return (
    <div className="space-y-6">
      {/* Metric Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {[
          {
            label: "Total Applications",
            value: jobApp.totalJobApp,
            icon: FileCheck,
          },
          {
            label: "New",
            value: jobApp.newApplicants,
            icon: UserCheck,
          },
          {
            label: "Shortlisted",
            value: jobApp.shortlisted,
            icon: Building2,
          },
          {
            label: "Hired",
            value: jobApp.hired,
            icon: GraduationCap,
          },
        ].map((metric, index) => (
          <motion.div
            key={metric.label}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card className="p-6">
              <metric.icon className="h-8 w-8 mb-4 text-muted-foreground" />
              <h3 className="text-sm font-medium text-muted-foreground">
                {metric.label}
              </h3>
              <p className="text-2xl font-semibold mt-2">
                {loading ? <Loader2 className="animate-spin text-purple-500 h-6 w-6" /> : metric.value || 0}
              </p>
            </Card>
          </motion.div>
        ))}
      </div>

      {/* Applications by Role */}
      {/* <Card className="p-6">
        <h3 className="font-semibold mb-6">Applications by Role</h3>
        <div className="space-y-6">
          {loading ? (
            // Skeleton loader for roles
            <div className="space-y-4">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="space-y-2">
                  <div className="flex justify-between">
                    <span className="h-4 w-20 bg-gray-200 rounded animate-pulse" />
                    <span className="h-4 w-12 bg-gray-200 rounded animate-pulse" />
                  </div>
                  <div className="h-2 rounded-full bg-gray-200 animate-pulse" />
                </div>
              ))}
            </div>
          ) : (
            allJobApp.role &&
            allJobApp.role.map((role, index) => (
              <motion.div
                key={role.role}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="space-y-2"
              >
                <div className="flex justify-between">
                  <span>{role.role}</span>
                  <span>{role.count} applications</span>
                </div>
                <div className="h-2 rounded-full bg-muted overflow-hidden">
                  <motion.div
                    className="h-full bg-purple-500"
                    initial={{ width: 0 }}
                    animate={{
                      width: `${(role.count / jobApp.totalJobApp) * 100}%`,
                    }}
                    transition={{ delay: 0.5 }}
                  />
                </div>
              </motion.div>
            ))
          )}
        </div>
      </Card> */}

      <Card className="space-y-6 p-6">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold">Applications by Role</h3>
          {/* Add a link with the icon */}
          <Link
            to="/AllApplications" // Update with your actual route
            className="text-purple-500 hover:text-purple-700 flex items-center space-x-1"
            title="View All Applications"
          >
            <span className="text-sm font-medium">View All</span>
            <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="space-y-6">
            {Object.keys(groupedApplications).map((role, index) => {
              const { count } = groupedApplications[role];
              return (
                <motion.div
                  key={role}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="space-y-2"
                >
                  <div className="flex justify-between">
                    <span>{role}</span>
                    <span>{count} applications</span>
                  </div>
                  <div className="h-2 rounded-full bg-gray-200 overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{
                        width: `${(count / totalApplications) * 100}%`,
                      }}
                      transition={{ delay: 0.5 }}
                    />
                  </div>
                </motion.div>
              );
            })}
          </div>
        )}
      </Card>

    </div>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("highlights");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "highlights":
        return <DashboardHighlights />;
      case "visitors":
        return <VisitorInsights />;
      case "jobs":
        return <JobApplicationsView />;
      default:
        return <DashboardHighlights />;
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 border-r bg-muted/50 p-4 space-y-4">
          <div className="space-y-2">
            {[
              { id: "highlights", label: "Dashboard", icon: BarChart3 },
              { id: "visitors", label: "Visitor Insights", icon: Users },
              { id: "jobs", label: "Job Applications", icon: Briefcase },
              { id: "services", label: "Services", icon: FileText },
              { id: "security", label: "Security", icon: Shield },
              { id: "settings", label: "Settings", icon: Settings },
            ].map((item) => (
              <button
                key={item.id}
                variant={activeSection === item.id ? "secondary" : "ghost"}
                className={`w-full flex items-center justify-start p-2 text-sm font-medium rounded-md transition-colors 
                    ${
                      activeSection === item.id
                        ? "bg-gray-200 text-blue-700"
                        : "hover:bg-gray-100 text-gray-600"
                    }`}
                onClick={() => setActiveSection(item.id)}
              >
                <item.icon className="mr-3 h-5 w-5" />
                {item.label}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <header className="border-b bg-white shadow-sm">
            <div className="flex h-16 items-center px-6">
              <h2 className="text-lg font-semibold text-gray-800">
                AvancerPI Analytics
              </h2>
              <div className="ml-auto flex items-center space-x-6">
                <input
                  type="search"
                  placeholder="Search..."
                  className="w-[200px] px-3 py-2 rounded-md border border-gray-300 text-sm shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <div className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="h-8 w-8 hover:ring-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <img alt="Avatar" className="rounded-full" src={logo} />
                  </button>

                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-40 rounded-md bg-white shadow-lg border border-gray-200">
                      <button className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <LogOut className="mr-2 h-4 w-4" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          <main className="p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeSection}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {renderContent()}
              </motion.div>
            </AnimatePresence>
          </main>
        </div>
      </div>
    </div>
  );
};

// Login Form Component
// const LoginForm = ({ onLogin }) => {
//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-500/10 via-blue-500/10 to-pink-500/10"
//     >
//       <Card className="w-full max-w-md p-8 space-y-6">
//         <div className="text-center space-y-2">
//           <h1 className="text-3xl font-bold">Welcome Back</h1>
//           <p className="text-muted-foreground">
//             Enter your credentials to access the dashboard
//           </p>
//         </div>
//         <div className="space-y-4">
//           <div className="space-y-2">
//             <Input type="email" placeholder="Email" />
//           </div>
//           <div className="space-y-2">
//             <Input type="password" placeholder="Password" />
//           </div>
//           <Button className="w-full" onClick={onLogin}>
//             Sign In
//           </Button>
//         </div>
//       </Card>
//     </motion.div>
//   );
// };

// Main Component
export default function Component() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <AnimatePresence mode="wait">
      <Dashboard />
    </AnimatePresence>
  );
}
