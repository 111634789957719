import React, { useEffect, useRef } from "react";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Cloud,
  Shield,
  Zap,
  DollarSign,
  CheckCircle,
  ChevronRight,
  Server,
  Users,
  Clock,
  Lock,
} from "lucide-react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Soudi_leading_bank from "../../assets/SuccessStories/Soudi_leading_bank.jpg";

const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2, suffix = "" }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      <span>{suffix}</span>
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

const SaudiLeadingBankSuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <Box sx={{ minHeight: "100vh", bgcolor: "background.paper" }}>
        {/* Hero Section */}
        <div className="relative h-[500px] md:min-h-screen flex items-center justify-center overflow-hidden">
          {/* Background Image */}
          <div className="absolute inset-0">
            <img
              src={Soudi_leading_bank}
              alt="Digital Transformation in Saudi Arabia"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Content */}
          <div className="absolute bottom-0 max-w-4xl px-4 mb-5 text-center bg-black bg-opacity-50 rounded-xl p-6 flex flex-col items-center justify-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Empowering Saudi's Top Bank with Advanced Private Cloud Automation
            </h1>
            <p className="text-lg md:text-xl text-gray-300">
              Revolutionizing banking infrastructure with tailored cloud
              solutions
            </p>
          </div>
        </div>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Background and Challenge
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  One of Saudi Arabia's leading banks required a comprehensive
                  solution to automate the deployment of banking applications on
                  IaaS while adhering to diverse application designs. They
                  needed robust governance, Role-Based Access Control (RBAC),
                  automated disaster recovery, and cost governance tailored to
                  each application within a private cloud environment.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <div>
                  {[
                    {
                      icon: Cloud,
                      text: "Automating diverse banking application deployments on IaaS",
                    },
                    {
                      icon: Shield,
                      text: "Implementing robust governance and RBAC",
                    },
                    {
                      icon: Server,
                      text: "Setting up automated disaster recovery",
                    },
                    {
                      icon: DollarSign,
                      text: "Establishing cost governance for each application",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <item.icon className="mr-3 text-blue-500 text-xl" />
                      <p className="text-gray-600"> {item.text}</p>
                    </li>
                  ))}
                </div>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Application Deployment Automation",
                  description:
                    "Designed and implemented automated workflows for deploying diverse banking applications on IaaS, ensuring adherence to specific application designs.",
                  icon: Cloud,
                },
                {
                  title: "Robust Governance",
                  description:
                    "Established RBAC governance for fine-grained control of resources at the application level.",
                  icon: Lock,
                },
                {
                  title: "Disaster Recovery Automation",
                  description:
                    "Configured automated failover and failback for critical banking applications with scheduled sanity checks.",
                  icon: Shield,
                },
                {
                  title: "Cost Governance Setup",
                  description:
                    "Deployed cost governance frameworks for real-time cost visibility and control at the application level.",
                  icon: DollarSign,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-cyan-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Impact Section */}
        <Box sx={{ py: 10, bgcolor: "background.paper" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h2" align="center" gutterBottom>
                Business Benefits
              </Typography>
            </ParallaxSection>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              {[
                {
                  value: 100,
                  label: "Automated IaaS for Banking Application",
                  icon: Cloud,
                  suffix: "%",
                },
                {
                  value: 100,
                  label: "Drastically Reduced RPO and RTO",
                  icon: Clock,
                  suffix: "%",
                },
                {
                  value: 100,
                  label: "Cost Visibility for Banking Apps",
                  icon: DollarSign,
                  suffix: "%",
                },
                {
                  value: 100,
                  label: "End-to-End Governance",
                  icon: Shield,
                  suffix: "%",
                },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <GlowingCard className="h-[250px] text-center bg-gray-50 p-6 rounded-lg shadow-lg">
                    <CardContent sx={{ textAlign: "center" }}>
                      <item.icon className="w-12 h-12 mx-auto mb-4 text-cyan-600" />
                      <Typography variant="h4" component="div" gutterBottom>
                        {item.value ? (
                          <AnimatedCounter
                            target={item.value}
                            suffix={item.suffix}
                          />
                        ) : (
                          <CheckCircle size={48} color="#4CAF50" />
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.label}
                      </Typography>
                    </CardContent>
                  </GlowingCard>
                </Grid>
              ))}
            </Grid>
            <motion.div
              className="text-base md:text-lg text-center max-w-3xl mx-auto text-gray-600"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-gray-900">
                Key Achievements:
              </h3>
              <ul className="list-disc list-inside space-y-2 text-left">
                <li>
                  Enhanced Agility: Automated banking application deployment
                  reduced provisioning time and errors.
                </li>
                <li>
                  Improved Resilience: Automated DR workflows ensured high
                  availability and reduced downtime risks.
                </li>
                <li>
                  Granular Governance: RBAC and cost governance provided
                  complete visibility and control over resources.
                </li>
                <li>
                  Operational Efficiency: Enabled the IT team to focus on
                  strategic initiatives instead of manual management tasks.
                </li>
              </ul>
            </motion.div>
          </Container>
        </Box>

        {/* Testimonial Section */}
        <Box sx={{ py: 10, bgcolor: "grey.100" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h2" align="center" gutterBottom>
                Client Testimonial
              </Typography>
            </ParallaxSection>
            <GlowingCard sx={{ maxWidth: "800px", mx: "auto" }}>
              <CardContent>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontStyle: "italic" }}
                >
                  "The advanced private cloud automation solution has
                  revolutionized our banking infrastructure. We've seen
                  remarkable improvements in deployment speed, disaster
                  recovery, and overall operational efficiency. This
                  transformation has positioned us to better serve our customers
                  and stay ahead in the competitive banking landscape."
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  – CIO, Leading Bank in Saudi Arabia
                </Typography>
              </CardContent>
            </GlowingCard>
          </Container>
        </Box>

        {/* Call to Action Section */}
        <Box
          sx={{
            py: 10,
            bgcolor: "black",
            color: "white",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              inset: 0,
              opacity: 0.2,
            }}
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #4CAF50 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #4CAF50 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #4CAF50 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <Container
            maxWidth="lg"
            sx={{ textAlign: "center", position: "relative", zIndex: 1 }}
          >
            <Typography variant="h2" gutterBottom>
              Transform your banking infrastructure with tailored cloud
              solutions
            </Typography>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="/ContactUs">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ChevronRight />}
                  sx={{
                    mt: 2,
                    py: 1.5,
                    px: 4,
                    borderRadius: "9999px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s",
                    },
                  }}
                >
                  Contact Our Experts
                </Button>
              </Link>
            </motion.div>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SaudiLeadingBankSuccessStory;
