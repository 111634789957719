import React, { useEffect } from "react";
import axios from "axios";

const TimeTracker = ({ children, pageName }) => {
  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const timeSpentMilliseconds = endTime - startTime;
      const timeSpentMinutes = (timeSpentMilliseconds / 60000).toFixed(2); // Convert to minutes and round to 2 decimal places

      if (timeSpentMinutes > 0) {
        console.log(`Time spent on ${pageName}: ${timeSpentMinutes} minutes`);

        axios
          .post("/api/tracktime", {
            page: pageName,
            timeSpent: timeSpentMinutes,
          })
          .catch(console.error);
      } else {
        console.log(`No significant time spent on ${pageName}. API call skipped.`);
      }
    };
  }, [pageName]);

  return <>{children}</>;
};

export default TimeTracker;
