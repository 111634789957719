import React, { useEffect, useRef } from "react";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";

import {
  Clock,
  Zap,
  CheckCircle,
  Cloud,
  Database,
  Server,
  ChevronRight,
  Network,
  Lock,
  FileText,
} from "lucide-react";
import { Link } from "react-router-dom";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import IndianSuccessStory from "../../assets/SuccessStories/IndianCommunicationInnovatorSuccessStory.jpg";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2 }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      {target !== 100 && <span>x</span>}
      {target === 100 && <span>%</span>}
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

const IndianCommunicationInnovatorSuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <Box sx={{ minHeight: "100vh", bgcolor: "background.paper" }}>
        {/* Hero Section */}
        <div className="relative flex items-end justify-center h-[500px] md:min-h-screen overflow-hidden">
          <img
            src={IndianSuccessStory}
            alt="High-Performance Storage Solutions for Digital Communication"
            className="absolute inset-0 w-full h-full object-cover"
          />
          {/* Overlay Animation */}
          <motion.div
            className="absolute inset-0 z-0"
            animate={{
              backgroundColor: [
                "rgba(0,0,0,0.3)",
                "rgba(0,0,0,0.5)",
                "rgba(0,0,0,0.3)",
              ],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="relative z-10 container mx-auto px-6 md:px-12 text-center mb-6 text-white">
            <div className="p-6 rounded-lg bg-black bg-opacity-50">
              <h1 className="text-3xl md:text-5xl font-bold mb-4">
                Empowering India's Leading Communication Innovator with
                High-Performance Storage Solutions
              </h1>
              <p className="text-lg md:text-xl">
                A journey to scalable, high-performing infrastructure for
                evolving application workloads
              </p>
            </div>
          </div>
        </div>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Background and Challenge
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  India's leading digital communication platform provider
                  required a high-performing infrastructure to support its
                  growing application workloads. The challenge was to integrate
                  a distributed storage solution that could seamlessly cater to
                  both traditional and modern applications while addressing
                  custom requirements for advanced accessibility and
                  configuration.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <div>
                  {[
                    {
                      icon: Cloud,
                      text: "Building a high-performance platform leveraging Nutanix Distributed Storage Fabric for Kubernetes",
                    },
                    {
                      icon: Database,
                      text: "Enabling Object Store support for both traditional and modern applications",
                    },
                    {
                      icon: Server,
                      text: "Deploying and integrating critical components including clusters, object storage, volume groups, and file systems",
                    },
                    {
                      icon: Lock,
                      text: "Addressing custom requirements such as SFTP access and Dremio integration with Object Store",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <item.icon className="mr-3 text-blue-500 text-xl" />
                      <p className="text-gray-600"> {item.text}</p>
                    </li>
                  ))}
                </div>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Cluster Deployment",
                  description:
                    "Deployed and configured Kubernetes clusters leveraging Nutanix's Distributed Storage Fabric for enhanced performance.",
                  icon: Cloud,
                },
                {
                  title: "Object Store Deployment",
                  description:
                    "Implemented Object Store services to support both traditional and modern application architectures.",
                  icon: Database,
                },
                {
                  title: "Volume and Files Deployment",
                  description:
                    "Deployed volume groups and file storage solutions for efficient data management and accessibility.",
                  icon: FileText,
                },
                {
                  title: "SFTP Integration",
                  description:
                    "Configured SFTP access for seamless interaction with the Object Store.",
                  icon: Lock,
                },
                {
                  title: "Dremio Integration",
                  description:
                    "Integrated Dremio with Object Store for advanced analytics and data querying capabilities.",
                  icon: Network,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-cyan-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Impact Section */}
        <Box sx={{ py: 10, bgcolor: "background.paper" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h3" align="center" gutterBottom>
                The Impact
              </Typography>
            </ParallaxSection>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              {[
                { value: 5, label: "Faster Storage Access", icon: Zap },
                { value: 100, label: "App Compatibility", icon: CheckCircle },
                { value: 100, label: "Seamless  Integration", icon: Network },
                { value: 0, label: "Downtime During Deployment", icon: Clock },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <GlowingCard className="h-[250px] bg-gray-50 p-6 rounded-lg shadow-lg">
                    <CardContent sx={{ textAlign: "center" }}>
                      <item.icon className="w-12 h-12 mx-auto mb-4 text-cyan-600" />
                      <Typography variant="h4" component="div" gutterBottom>
                        <AnimatedCounter target={item.value} />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.label}
                      </Typography>
                    </CardContent>
                  </GlowingCard>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mx: "auto" }}
            >
              Our solution dramatically improved storage access speed, ensured
              compatibility across all applications, and achieved seamless
              integration of custom requirements. This high-performance
              infrastructure positions the organization for continued innovation
              and growth in the dynamic digital communication landscape.
            </Typography>
          </Container>
        </Box>

        {/* Testimonial Section */}
        <Box sx={{ py: 10, bgcolor: "grey.100" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h3" align="center" gutterBottom>
                What Our Client Says
              </Typography>
            </ParallaxSection>
            <GlowingCard sx={{ maxWidth: "800px", mx: "auto" }}>
              <CardContent>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontStyle: "italic" }}
                >
                  "The high-performance storage solution delivered by the team
                  has revolutionized our operations. Their expertise in
                  integrating custom requirements like SFTP and Dremio ensures
                  our platform remains future-ready and highly efficient."
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  – IT Manager, India's Leading Communication Platform
                </Typography>
              </CardContent>
            </GlowingCard>
          </Container>
        </Box>

        {/* Call to Action Section */}
        <Box
          sx={{
            py: 10,
            bgcolor: "black",
            color: "white",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              inset: 0,
              opacity: 0.2,
            }}
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <Container
            maxWidth="lg"
            sx={{ textAlign: "center", position: "relative", zIndex: 1 }}
          >
            <Typography variant="h3" gutterBottom>
              Discover how we can optimize your infrastructure
            </Typography>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="/ContactUs">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ChevronRight />}
                  sx={{
                    mt: 2,
                    py: 1.5,
                    px: 4,
                    borderRadius: "9999px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </motion.div>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default IndianCommunicationInnovatorSuccessStory;
