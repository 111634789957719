import React, { useEffect, useState } from 'react';
import { TextField, DialogContent } from '@mui/material';
import axios from 'axios';

const MyComponent = ({ candidate, isTextBoxLocked, handleTextBoxChange }) => {
  const [textBoxContent, setTextBoxContent] = useState({});

  useEffect(() => {
    // Fetch data for the specific `appid`
    const fetchContent = async () => {
      try {
        const response = await axios.get(
          `api/job_application/addPoints_candidate`,
          {
            params: {
              appid: candidate.appid,
            },
          }
        );
        const data = response.data?.data?.[0]?.content || '';
        // Update the state with the fetched content
        setTextBoxContent((prev) => ({
          ...prev,
          [candidate.appid]: data,
        }));
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent();
  }, [candidate.appid]); // Only run when `candidate.appid` changes

  return (
    <DialogContent>
      <TextField
        fullWidth
        multiline
        rows={8}
        value={textBoxContent[candidate.appid] || ''} // Use the fetched content
        onChange={(e) => handleTextBoxChange(candidate.appid, e.target.value)}
        disabled={isTextBoxLocked[candidate.appid]}
        margin="normal"
        sx={{
          fontSize: '1.2rem',
        }}
      />
    </DialogContent>
  );
};

export default MyComponent;
